import React from "react";
import { useTranslate, useCustomMutation } from "@refinedev/core";
import { ThemedTitleV2 } from "components/layout/title";
import { Form, Input, Button, Card, Typography, Layout, Row, Col } from "antd";
import { useApiUrl } from "@refinedev/core";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "@refinedev/antd";
import { notification } from "antd";
import { authProvider } from "../../authProvider";

interface IUserInviteAcceptResponse {
    data: IUserInviteAcceptResponseData;
    message: string;
    status: string;
    success: boolean;
}

interface IUserInviteAcceptResponseData {
    success: string;
    user: number;
    email: string;
}

export const InvitedRegisterPage: React.FC = () => {
    const { formProps, saveButtonProps } = useForm({
        warnWhenUnsavedChanges: false,
    });
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { key } = useParams();
    const { mutate: claimInvite, isLoading } = useCustomMutation();
    const navigate = useNavigate();

    const onFinish = async (values: Record<string, unknown>) => {
        console.log(values);
        claimInvite(
            {
                url: `${apiUrl}/claim-invitation/`,
                method: "post",
                values: { ...values, key },
            },
            {
                onSuccess: async (response: IUserInviteAcceptResponse) => {
                    const loginResponse = await authProvider.login({
                        email: response?.data?.email,
                        password: values.password as string,
                    });
                    if (loginResponse.success) {
                        notification.success({
                            message: t(
                                "pages.register.success",
                                "Registration successful"
                            ),
                            description: t(
                                "pages.register.loggedIn",
                                "You have been logged in automatically"
                            ),
                        });
                        navigate("/");
                    } else {
                        notification.warning({
                            message: t(
                                "pages.register.success",
                                "Registration successful"
                            ),
                            description: t(
                                "pages.register.loginFailed",
                                "Please log in with your credentials."
                            ),
                        });
                        navigate("/login");
                    }
                },
                onError: (error: {
                    response: {
                        data: {
                            error?: string;
                            errors?: Record<string, string>;
                        };
                    };
                }) => {
                    notification.error({
                        message: t(
                            "pages.register.error",
                            "Registration failed"
                        ),
                        description:
                            error?.response?.data?.error ||
                            Object.values(
                                error?.response?.data?.errors || {}
                            ).join(", ") ||
                            t(
                                "pages.register.genericError",
                                "An error occurred during registration"
                            ),
                    });
                },
            }
        );
    };

    return (
        <Layout className="min-h-screen bg-gray-100">
            <Row justify="center" align="middle" className="min-h-screen">
                <Col xs={22} sm={16} md={12} lg={10}>
                    <div className="text-center mb-8">
                        <ThemedTitleV2 collapsed={false} />
                    </div>
                    <Card
                        title={
                            <Typography.Title
                                level={3}
                                className="text-center my-4"
                            >
                                {t(
                                    "pages.register.title",
                                    "Complete your registration"
                                )}
                            </Typography.Title>
                        }
                    >
                        <Form
                            {...formProps}
                            layout="vertical"
                            onFinish={onFinish}
                            requiredMark={false}
                        >
                            <Form.Item
                                name="name"
                                label={t("pages.register.name", "Name")}
                                rules={[
                                    { required: true },
                                    {
                                        type: "string",
                                        message: t(
                                            "pages.register.errors.name",
                                            "Invalid name"
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={t(
                                        "pages.register.fields.name",
                                        "Name and Surname"
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                name="role"
                                label={t("pages.register.role", "Role")}
                                rules={[
                                    { required: true },
                                    {
                                        type: "string",
                                        message: t(
                                            "pages.register.errors.role",
                                            "Invalid role"
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={t(
                                        "pages.register.fields.role",
                                        "Company role (e.g. 'accounting')"
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label={t(
                                    "pages.register.fields.password",
                                    "Password"
                                )}
                                rules={[
                                    { required: true },
                                    {
                                        min: 8,
                                        message: t(
                                            "pages.updatePassword.errors.passwordTooShort",
                                            "Password must be at least 8 characters long"
                                        ),
                                    },
                                    {
                                        pattern: /[A-Z]/,
                                        message: t(
                                            "pages.register.errors.passwordUppercase",
                                            "Password must contain at least one uppercase letter"
                                        ),
                                    },
                                    {
                                        pattern: /[a-z]/,
                                        message: t(
                                            "pages.register.errors.passwordLowercase",
                                            "Password must contain at least one lowercase letter"
                                        ),
                                    },
                                    {
                                        pattern: /[0-9]/,
                                        message: t(
                                            "pages.register.errors.passwordNumber",
                                            "Password must contain at least one number"
                                        ),
                                    },
                                    {
                                        pattern: /[!@#$%^&*(),.?":{}|<>]/,
                                        message: t(
                                            "pages.register.errors.passwordSpecial",
                                            "Password must contain at least one special character"
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    type="password"
                                    placeholder="●●●●●●●●"
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label={t(
                                    "pages.updatePassword.fields.confirmPassword",
                                    "Confirm Password"
                                )}
                                hasFeedback
                                dependencies={["password"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("password") ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        "pages.updatePassword.errors.confirmPasswordNotMatch",
                                                        "Passwords do not match"
                                                    )
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    type="password"
                                    placeholder="●●●●●●●●"
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    size="large"
                                    {...saveButtonProps}
                                    loading={isLoading}
                                    block
                                >
                                    {t(
                                        "pages.register.buttons.submit",
                                        "Complete Registration"
                                    )}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};
