import { Create, useForm } from "@refinedev/antd";
import { ContentDocumentForm } from "./form";

export const ContentDocumentCreate = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <ContentDocumentForm data={undefined} formProps={formProps} />
        </Create>
    );
};
