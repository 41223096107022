import React, { useEffect, useState } from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useCustomMutation,
    useApiUrl,
    useInvalidate,
    useList,
    useMany,
    BaseKey,
} from "@refinedev/core";
import {
    List,
    useTable,
    EditButton,
    ShowButton,
    DeleteButton,
    getDefaultSortOrder,
} from "@refinedev/antd";
import { Table, Button, Breadcrumb, Select } from "antd";
import { Evaluation } from "./types";
import { renderEvaluationStatusTag } from "components/evaluation/evaluation-status-tag";
import { useNavigate } from "react-router-dom";
import { CreateButton } from "@refinedev/antd";
import { OrderedListOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { FilterDropdown } from "@refinedev/antd";
import { EvaluationQuestionSet } from "pages/evaluation-question-sets/types";
import { AssistantType } from "pages/assistants/types";
import { ContentDocumentType } from "pages/content-documents/types";

export const EvaluationList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { tableProps, sorter } = useTable<Evaluation>({
        syncWithLocation: true,
        initialSorter: [
            {
                field: "name",
                order: "desc",
            },
        ],
        filters: {
            initial: [
                {
                    field: "question_set_name",
                    operator: "eq",
                    value: undefined,
                },
                {
                    field: "assistant_name",
                    operator: "eq",
                    value: undefined,
                },
                {
                    field: "content_document_title",
                    operator: "eq",
                    value: undefined,
                },
                {
                    field: "status",
                    operator: "eq",
                    value: undefined,
                },
            ],
        },
    });
    const navigate = useNavigate();
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { mutate: runEvaluation } = useCustomMutation();
    const invalidate = useInvalidate();
    const [hasInProgressEvaluation, setHasInProgressEvaluation] =
        useState(false);

    const { data: evaluations } = useList<Evaluation>({
        resource: "evaluations",
    });

    const questionSetIds = Array.from(
        new Set(evaluations?.data.map((e: Evaluation) => e.question_set) || [])
    );
    const assistantIds = Array.from(
        new Set(evaluations?.data.map((e: Evaluation) => e.assistant) || [])
    );
    const documentIds = Array.from(
        new Set(evaluations?.data.map((e: Evaluation) => e.document) || [])
    );

    const { data: questionSets } = useMany({
        resource: "evaluation-question-sets",
        ids: questionSetIds as BaseKey[],
        queryOptions: { enabled: questionSetIds.length > 0 },
    });

    const { data: assistants } = useMany({
        resource: "assistants",
        ids: assistantIds as BaseKey[],
        queryOptions: { enabled: assistantIds.length > 0 },
    });

    const { data: documents } = useMany({
        resource: "content-documents",
        ids: documentIds as BaseKey[],
        queryOptions: { enabled: documentIds.length > 0 },
    });

    const questionSetOptions =
        questionSets?.data.map((qs: EvaluationQuestionSet) => ({ label: qs.name, value: qs.id })) ||
        [];
    const assistantOptions =
        assistants?.data.map((a: AssistantType) => ({ label: a.name, value: a.id })) || [];
    const documentOptions =
        documents?.data.map((d: ContentDocumentType) => ({ label: d.title, value: d.id })) || [];

    const statusOptions = [
        { label: t("evaluations.status.draft", "Draft"), value: "draft" },
        { label: t("evaluations.status.inProgress", "In Progress"), value: "in_progress" },
        { label: t("evaluations.status.completed", "Completed"), value: "completed" },
        { label: t("evaluations.status.failed", "Failed"), value: "failed" },
    ];

    useEffect(() => {
        const inProgressEvaluation = evaluations?.data.some(
            (evaluation: Evaluation) => evaluation.status === "in_progress"
        );
        setHasInProgressEvaluation(inProgressEvaluation || false);
    }, [evaluations]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (hasInProgressEvaluation) {
            interval = setInterval(() => {
                invalidate({
                    resource: "evaluations",
                    invalidates: ["list"],
                });
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [hasInProgressEvaluation, invalidate]);

    const breadcrumbItems = [
        {
            title: t("evaluations.title", "Evaluations"),
        },
    ];

    return (
        <List
            breadcrumb={<Breadcrumb items={breadcrumbItems} />}
            headerButtons={({ createButtonProps }) => (
                <>
                    <Button
                        size="middle"
                        onClick={() =>
                            navigate("/evaluation-question-sets/list")
                        }
                        icon={<OrderedListOutlined />}
                    >
                        {t(
                            "evaluations.actions.viewQuestionSets",
                            "View Question Sets"
                        )}
                    </Button>

                    <CreateButton {...createButtonProps} />
                </>
            )}
        >
            <Table {...tableProps} rowKey="id" scroll={{ x: "max-content" }}>
                <Table.Column
                    dataIndex="id"
                    title={t("evaluations.fields.id", "ID")}
                    sorter={{ multiple: 1 }}
                    defaultSortOrder={getDefaultSortOrder("id", sorter)}
                />
                <Table.Column
                    dataIndex={["name"]}
                    title={t("evaluations.fields.name", "Name")}
                    sorter={{ multiple: 2 }}
                />
                <Table.Column
                    dataIndex={["question_set_id"]}
                    title={t("evaluations.fields.questionSet", "Question Set")}
                    render={(value, record: Evaluation) =>
                        record.question_set_name
                    }
                    filterDropdown={(props) => (
                        <FilterDropdown {...props}>
                            <Select
                                allowClear
                                placeholder={t(
                                    "evaluations.fields.questionSet",
                                    "Question Set"
                                )}
                                options={questionSetOptions}
                                style={{ width: "200px" }} // Add this line
                            />
                        </FilterDropdown>
                    )}
                    sorter={{ multiple: 3 }}
                />
                <Table.Column
                    dataIndex={["assistant_id"]}
                    title={t("evaluations.fields.assistant", "Assistant")}
                    render={(value, record: Evaluation) =>
                        record.assistant_name
                    }
                    filterDropdown={(props) => (
                        <FilterDropdown {...props}>
                            <Select
                                allowClear
                                placeholder={t(
                                    "evaluations.fields.assistant",
                                    "Assistant"
                                )}
                                style={{ width: "200px" }}
                                options={assistantOptions}
                            />
                        </FilterDropdown>
                    )}
                    sorter={{ multiple: 4 }}
                />
                <Table.Column
                    dataIndex={["document_id"]}
                    title={t("evaluations.fields.document", "Document")}
                    render={(value, record: Evaluation) =>
                        record.content_document_title
                    }
                    filterDropdown={(props) => (
                        <FilterDropdown {...props}>
                            <Select
                                allowClear
                                style={{ width: "200px" }}
                                placeholder={t(
                                    "evaluations.fields.document",
                                    "Document"
                                )}
                                options={documentOptions}
                            />
                        </FilterDropdown>
                    )}
                    sorter={{ multiple: 5 }}
                />
                <Table.Column
                    dataIndex={["status"]}
                    title={t("evaluations.fields.status", "Status")}
                    render={(value) =>
                        renderEvaluationStatusTag(value, translate)
                    }
                    filterDropdown={(props) => (
                        <FilterDropdown {...props}>
                            <Select
                                allowClear
                                style={{ width: "200px" }}
                                placeholder={t(
                                    "evaluations.fields.status",
                                    "Status"
                                )}
                                options={statusOptions}
                            />
                        </FilterDropdown>
                    )}
                    sorter={{ multiple: 6 }}
                />
                <Table.Column
                    dataIndex={["generated_grade"]}
                    title={t(
                        "evaluations.fields.generatedGrade",
                        "Generated Grade Average"
                    )}
                    render={(value) => `${value ? value.toFixed(1) : 0}%`}
                    sorter={{ multiple: 7 }}
                />
                <Table.Column
                    title={t("table.actions")}
                    dataIndex="actions"
                    align="center"
                    width={140}
                    fixed="right"
                    render={(_, record: Evaluation) => (
                        <div className="flex justify-center space-x-1">
                            <EditButton
                                hideText
                                disabled={record.status !== "draft"}
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                disabled={record.status === "in_progress"}
                                size="small"
                                recordItemId={record.id}
                            />
                            <Button
                                size="small"
                                disabled={record.status !== "draft"}
                                className="text-lime-500 outline-lime-500 border-lime-500"
                                icon={<PlayCircleOutlined />}
                                onClick={() =>
                                    runEvaluation({
                                        url: `${apiUrl}/evaluations/${record.id}/run-evaluation/`,
                                        method: "post",
                                        values: {}, // Add any values you want to send in the request body
                                        successNotification: () => {
                                            invalidate({
                                                resource: "evaluations",
                                                invalidates: ["list"],
                                            });
                                            return {
                                                message: t(
                                                    "evaluations.notifications.start.success",
                                                    "Evaluation started"
                                                ),
                                                description: t(
                                                    "evaluations.notifications.start.success_description",
                                                    "The evaluation has been started successfully"
                                                ),
                                                type: "success",
                                            };
                                        },
                                        errorNotification: () => ({
                                            message: t(
                                                "evaluations.notifications.start.error",
                                                "Evaluation failed"
                                            ),
                                            description: t(
                                                "evaluations.notifications.start.error_description",
                                                "There was an error starting the evaluation"
                                            ),
                                            type: "error",
                                        }),
                                    })
                                }
                            />
                        </div>
                    )}
                />
            </Table>
        </List>
    );
};

export default EvaluationList;
