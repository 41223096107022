import React from "react";
import { useTranslate } from "@refinedev/core";
import { Select } from "antd";

interface AssistantSelectProps {
    value?: string;
    onChange?: (value: string) => void;
    defaultValue?: string;
}

const AssistantSelect: React.FC<AssistantSelectProps> = ({
    value,
    onChange,
    defaultValue,
}) => {
    const t = useTranslate();

    const LANGUAGE_MODEL_CHOICES = [
        {
            value: "gpt-3.5-turbo",
            label: t("assistants.fields.models.gpt3.5", "GPT-3.5 Turbo"),
        },
        {
            value: "gpt-4",
            label: t("assistants.fields.models.gpt4", "GPT-4 Turbo"),
        },
        {
            value: "gpt-4o",
            label: t("assistants.fields.models.gpt4o", "GPT-4o"),
        },
        {
            value: "gpt-4o-mini",
            label: t("assistants.fields.models.gpt4o-mini", "GPT-4o-mini"),
        },
    ];

    return (
        <Select
            options={LANGUAGE_MODEL_CHOICES}
            value={value}
            defaultValue={value || defaultValue || "gpt-4o-mini"}
            onChange={onChange}
            className="w-full"
        />
    );
};

export { AssistantSelect };
