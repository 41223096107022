import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./en.json";
import slTranslations from "./sl.json";

const resources = {
    en: enTranslations,
    sl: slTranslations,
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

export const i18nProvider = {
    translate: i18n.t,
    changeLocale: i18n.changeLanguage,
    getLocale: i18n.default.language,
};
