import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { EvaluationForm } from "./form";

export const EvaluationCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <EvaluationForm formProps={formProps} />
        </Create>
    );
};

export default EvaluationCreate;
