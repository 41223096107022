import { Typography } from "antd/lib";
import { Row, Col, Layout, Card } from "antd";
// import { useParams } from "react-router-dom";
// import { useCustom, useApiUrl } from "@refinedev/core";

export const VerifyEmailPage = () => {
    // const { token } = useParams();

    // const apiUrl = useApiUrl();

    // const { data, isLoading } = useCustom({
    //     url: `${apiUrl}/_allauth/browser/v1/auth/email/verify`,
    //     method: "post",
    //     config: {
    //         payload: {
    //             key: token,
    //             message: "Email verified successfully!"
    //         },
    //     },
    // });
    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    return (
        <Layout
            style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Row>
                <Col xs={22}>
                    <Card>
                        <Typography.Title level={3}>
                            Verify Email
                        </Typography.Title>
                        <Typography.Text>
                            Your email has been verified! Login
                        </Typography.Text>
                        <Typography.Link href="/login"> here</Typography.Link>
                        <Typography.Text>.</Typography.Text>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};
