import React, { ReactNode, useState } from "react";
import { Button, Card } from "antd";
import {
    ArrowLeftOutlined,
    MessageOutlined,
} from "@ant-design/icons";
import { EditButton, Show } from "@refinedev/antd";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { Assistant, ContentType } from "components/assistant";
import { isMobile } from "react-device-detect";
import { usePermissions } from "@refinedev/core";
interface AssistantModalProps {
    isVisible: boolean;
    assistantId: number;
    contentType: ContentType;
    contentId: number;
    jumpToPage?: (page: number) => void;
    setAssistantVisible: (visible: boolean) => void;
}

const AssistantModal: React.FC<AssistantModalProps> = (props) => {
    return (
        <Card
            className="fixed bottom-4 right-4 z-50 w-96 h-[43vh] bg-white rounded-lg shadow-lg overflow-hidden"
            styles={{
                body: {
                    backgroundColor: "#F5F5F5",
                },
            }}
            style={{
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                display: props.isVisible ? "block" : "none",
            }}
        >
            <Assistant {...props} isModal enableHideButton />
        </Card>
    );
};

interface ShowContentAssistantProps {
    title: string;
    contentType: ContentType;
    contentId: number;
    assistantId: number;
    assistantUuid: string;
    children: ReactNode;
    jumpToPage?: (page: number) => void;
    extraHeaderButtons?: ReactNode;
    breadcrumb?: ReactNode;
    desktopDisplay?: "sidebar" | "modal";
}

export const ShowContentAssistant: React.FC<ShowContentAssistantProps> = ({
    title,
    contentType,
    contentId,
    assistantId,
    children,
    jumpToPage,
    breadcrumb,
    extraHeaderButtons,
    desktopDisplay = "sidebar",
}) => {
    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const { data: permissionsData } = usePermissions({});
    const isAdmin = permissionsData?.includes("admin");

    return (
        <div className="overflow-hidden">
            <Show
                breadcrumb={breadcrumb}
                title={title}
                goBack={
                    !title ? null : (
                        <Button icon={<ArrowLeftOutlined />} type="text" />
                    )
                }
                contentProps={{
                    style: {
                        padding: "0px",
                        backgroundColor: "transparent",
                    },
                    bodyStyle: {
                        padding: "0px",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            {extraHeaderButtons}
                            {isAdmin && (
                                <>
                                    <EditButton {...editButtonProps} />
                                </>
                            )}
                        </>
                    );
                }}
            >
                {isMobile ? (
                    <>
                        <div style={{ height: "calc(100vh - 200px)" }}>
                            {children}
                        </div>
                        <div
                            className={`fixed inset-0 z-50 bg-gray-200 p-6 w-full transition-opacity duration-100 ${
                                isAssistantVisible
                                    ? "opacity-100"
                                    : "opacity-0 pointer-events-none"
                            }`}
                        >
                            <Assistant
                                assistantId={assistantId}
                                contentType={contentType}
                                contentId={contentId}
                                jumpToPage={jumpToPage}
                                enableHideButton
                                setAssistantVisible={setAssistantVisible}
                                isMobile
                            />
                        </div>
                    </>
                ) : desktopDisplay === "sidebar" ? (
                    <PanelGroup
                        direction="horizontal"
                        className="!overflow-visible"
                        style={{
                            height: "78vh",
                        }}
                    >
                        <Panel
                            id="P-content"
                            minSize={40}
                            defaultSize={40}
                            style={{
                                overflowY: "scroll",
                            }}
                        >
                            {children}
                        </Panel>
                        <PanelResizeHandle
                            className="mx-1 w-2 hover:bg-gray-200 transition-colors"
                            hidden={!isAssistantVisible}
                        />
                        <Panel
                            hidden={!isAssistantVisible}
                            id="P-assistant"
                            minSize={isAssistantVisible ? 30 : 0}
                        >
                            <Assistant
                                assistantId={assistantId}
                                contentType={contentType}
                                contentId={contentId}
                                jumpToPage={jumpToPage}
                                enableHideButton
                                setAssistantVisible={setAssistantVisible}
                            />
                        </Panel>
                    </PanelGroup>
                ) : (
                    <>
                        <div className="h-[78vh] overflow-y-auto">
                            {children}
                        </div>
                    </>
                )}
            </Show>
            {!isAssistantVisible && assistantId && (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<MessageOutlined style={{ fontSize: "24px" }} />}
                    size="large"
                    className="fixed bottom-4 right-4 z-50"
                    onClick={() => setAssistantVisible(!isAssistantVisible)}
                    style={{
                        width: "48px",
                        height: "48px",
                    }}
                />
            )}
            {desktopDisplay === "modal" && (
                <AssistantModal
                    isVisible={isAssistantVisible}
                    assistantId={assistantId}
                    contentType={contentType}
                    contentId={contentId}
                    jumpToPage={jumpToPage}
                    setAssistantVisible={setAssistantVisible}
                />
            )}
        </div>
    );
};
