import { BaseRecord, HttpError } from "@refinedev/core";
import { Button, Card, Space, Table, Tag } from "antd";
import { useTable } from "@refinedev/antd";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { IMessage } from "../types";
import { EyeOutlined } from "@ant-design/icons";
import moment from 'moment/min/moment-with-locales';
import { Link } from "react-router-dom";

export const ChatMessagesTable = () => {
    const { tableProps } = useTable<IMessage, HttpError>({
        resource: "stats-messages",
    });
    const { t } = useTranslation();

    return (
        <Card
            className="mt-4"
            title={t("chatMessagesTable.title", "Recent Messages")}
        >
            <Table
                {...tableProps}
                rowKey="id"
            >
                <Table.Column
                    dataIndex="assistant_name"
                    title={t(
                        "chatMessagesTable.columns.assistant",
                        "Assistant"
                    )}
                />
                <Table.Column
                    dataIndex="user_name"
                    title={t("chatMessagesTable.columns.user", "User")}
                />
                <Table.Column
                    dataIndex="user_group_name"
                    title={t("chatMessagesTable.columns.group", "Group")}
                />
                <Table.Column
                    dataIndex="role"
                    title={t("chatMessagesTable.columns.role", "Role")}
                    render={(role: string) =>
                        role === "assistant" ? (
                            <Tag color="red">
                                {t("chatMessagesTable.role.bot", "Bot")}
                            </Tag>
                        ) : (
                            <Tag color="green">
                                {t("chatMessagesTable.role.user", "User")}
                            </Tag>
                        )
                    }
                />
                <Table.Column
                    dataIndex="content"
                    title={t("chatMessagesTable.columns.content", "Content")}
                    render={(text: string) =>
                        text.length > 40 ? `${text.slice(0, 40)}...` : text
                    }
                />
                <Table.Column
                    dataIndex="created_at"
                    title={t(
                        "chatMessagesTable.columns.timestamp",
                        "Timestamp"
                    )}
                    render={(value) =>
                        moment(value).format(
                            t(
                                "chatMessageHistory.dateFormat",
                                "MMM DD, YYYY hh:mm A"
                            )
                        )
                    }
                />
                <Table.Column
                    title={t(
                        "chatMessagesTable.columns.actions",
                        "Actions"
                    )}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => {
                        return (
                            <Space>
                                <Link to={`chat-message-history/${record.chat_message_history}`}>
                                    <Button
                                        size="small"
                                        icon={<EyeOutlined />}
                                    />
                                </Link>
                            </Space>
                        );
                    }}
                />
            </Table>
        </Card>
    );
};
