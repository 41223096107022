import { Card } from "antd";
import { InfoCardProps } from "../types";
import { useTranslation } from "react-i18next";

export function InfoCard({
    title,
    value,
    change,
    color,
    dateRange,
}: InfoCardProps) {
    const { t } = useTranslation();

    const dateRangeText = {
        lastMonth: t("stats.lastMonth", "last month"),
        lastWeek: t("stats.lastWeek", "last week"),
        custom:  t("stats.previousEquavivalentTimeframe", "previous equivalent timeframe"),
    }[dateRange];
    return (
        <Card className="py-4 px-4">
            <div className="flex justify-between">
                <h4 className="m-0 text-lg" style={{ color }}>
                    {title}
                </h4>
            </div>
            <h6 className=" text-4xl font-bold m-0" style={{ color }}>
                {value}
            </h6>
            <p
                className={`text-sm opacity-50 mt-4 ${
                    change > 0
                        ? "text-green-500"
                        : change < 0
                        ? "text-red-500"
                        : "text-black"
                }`}
            >
                {change > 0 ? "+" : ""}
                {(change * 100).toFixed(2)}% {t("stats.comparedTo", "compared to")} {dateRangeText}
            </p>
        </Card>
    );
}
