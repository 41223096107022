import { AuthProvider } from "@refinedev/core";
import { AuthActionResponse } from "@refinedev/core/dist/contexts/auth/types";

import { useEffect } from "react";

export const TOKEN_KEY = "token-access";
export const REFRESH_KEY = "token-refresh";
const baseUrl = window.location.origin;
import { getCSRFToken } from "./auth/django";
import { notification } from "antd";

interface IUser {
    id: number;
    name: string;
    avatar: string;
    user_group: string;
    is_superuser: boolean;
    is_tenant_admin: boolean;
    regular_user_mode: boolean;
    show_first_time_information_popup: boolean;
}

export interface IIdentity {
    id: number;
    email: string;
    avatar: string;
    name: string;
    user_group: number;
    is_superuser: boolean;
    show_first_time_information_popup: boolean;
}

export function useTokenRefresh() {
    useEffect(() => {
        const REFRESH_INTERVAL = 1000 * 60 * 2; // 2 minutes
        const interval = setInterval(() => {
            if (localStorage.getItem("token-refresh")) {
                updateToken();
            }
        }, REFRESH_INTERVAL);
        // Trigger the function at the beginning of the interval
        updateToken();
        return () => clearInterval(interval);
    }, []);
}

export async function updateToken(): Promise<boolean> {
    return await fetch(`${baseUrl}/api/token/refresh/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            refresh: localStorage.getItem(REFRESH_KEY),
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.code === "token_not_valid") {
                localStorage.removeItem(REFRESH_KEY);
                localStorage.removeItem(TOKEN_KEY);
                window.location.href = "/login";
                return false;
            }
            localStorage.setItem(TOKEN_KEY, data.access);
            return true;
        })
        .catch(() => {
            return false;
        });
}

export const authProvider: AuthProvider = {
    login: async ({ email, password }): Promise<AuthActionResponse> => {
        if (email && password) {
            const response = await fetch(`${baseUrl}/api/token/`, {
                // const response = await fetch(`${baseUrl}/_allauth/browser/v1/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCSRFToken(),
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem(REFRESH_KEY, data.refresh);
                localStorage.setItem(TOKEN_KEY, data.access);
                return {
                    success: true,
                    redirectTo: "/",
                };
            } else {
                return {
                    success: false,
                    error: {
                        name: "Login Error",
                        message:
                            data?.detail ||
                            data?.error ||
                            data?.message ||
                            "An error occurred while processing your request.",
                    },
                    redirectTo: "/login",
                };
            }
        }
        return {
            success: false,
            error: {
                name: "LoginError",
                message: "Email and password are required",
            },
            redirectTo: "/login",
        };
    },
    logout: async () => {
        localStorage.removeItem(REFRESH_KEY);
        localStorage.removeItem(TOKEN_KEY);
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    check: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token && token !== "undefined") {
            const refresh = updateToken();
            if (!refresh) {
                return {
                    authenticated: false,
                    redirectTo: "/login",
                };
            }
            return {
                authenticated: true,
            };
        }
        return {
            authenticated: false,
            redirectTo: "/login",
        };
    },
    getIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token && token !== "undefined") {
            const response = await fetch(`${baseUrl}/api/identity/get/`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                return {
                    id: data.id,
                    name: data.name || data.email,
                    avatar: data.profile_picture,
                    user_group: data.user_group,
                    is_superuser: data.is_superuser,
                    show_all_companies: data.show_all_companies,
                    regular_user_mode: data.regular_user_mode,
                    show_first_time_information_popup:
                        data.show_first_time_information_popup,
                };
            } else {
                return null;
            }
        }
        return null;
    },
    getPermissions: async () => {
        if (authProvider && authProvider.getIdentity) {
            const user = (await authProvider?.getIdentity()) as IUser;
            if (user && user.is_superuser && !user.regular_user_mode) {
                return ["admin"];
            }
            if (user && user.is_superuser && user.regular_user_mode) {
                return ["user", "hidden_admin"];
            }
        }
        return ["user"];
    },
    onError: async (error) => {
        return { error };
    },
    register: async ({
        email,
        password,
        registration_token,
        role,
        name,
        t,
    }) => {
        if (email && password && registration_token) {
            const response = await fetch(
                `${baseUrl}/api/_allauth/browser/v1/auth/signup`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCSRFToken(),
                    },
                    body: JSON.stringify({
                        email,
                        password,
                        registration_token: registration_token,
                        role,
                        name,
                    }),
                }
            );

            const data = await response.json();
            if (response.ok) {
                // Log the user in after successful registration
                const loginResponse = await authProvider.login({
                    email,
                    password,
                });
                if (loginResponse.success) {
                    notification.success({
                        message: t(
                            "notifications.registration.success",
                            "Registration successful"
                        ),
                        description: t(
                            "notifications.registration.loggedIn",
                            "You have been registered and logged in."
                        ),
                    });
                    return loginResponse;
                }
                // If login fails, still return success but with a different message
                notification.warning({
                    message: t(
                        "notifications.registration.success",
                        "Registration successful"
                    ),
                    description: t(
                        "notifications.registration.pleaseLogin",
                        "Please login to continue."
                    ),
                });
                return {
                    success: true,
                    redirectTo: "/login",
                };
            } else {
                return {
                    success: false,
                    error: {
                        name:
                            data?.detail ||
                            data?.error ||
                            data?.message ||
                            data?.errors?.[0]?.message ||
                            "Register Error",
                        message: "Register Error",
                    },
                };
            }
        }

        return {
            success: false,
            error: {
                name: "UnfilledFormError",
                message: "Please fill out the form completely",
            },
        };
    },
    forgotPassword: async ({ email, t }) => {
        try {
            // Fetch CSRF token
            await fetch(`${baseUrl}/api/csrf-token/`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            // Password reset request
            const response = await fetch(
                `${baseUrl}/api/_allauth/browser/v1/auth/password/request`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCSRFToken(),
                    },
                    body: JSON.stringify({ email }),
                }
            );

            if (response.ok) {
                notification.success({
                    message: t(
                        "notifications.forgotPassword.success",
                        "Password Reset"
                    ),
                    description: t(
                        "notifications.forgotPassword.description",
                        "If an account exists with this email, a password reset link has been sent."
                    ),
                });
                return {
                    success: true,
                    redirectTo: "/login",
                };
            } else {
                const data = await response.json();
                return {
                    success: false,
                    error: {
                        name: t(
                            "notifications.forgotPassword.error",
                            "Forgot Password Error"
                        ),
                        message:
                            data?.detail ||
                            data?.error ||
                            data?.message ||
                            t(
                                "notifications.forgotPassword.errorMessage",
                                "An error occurred while processing your request."
                            ),
                    },
                };
            }
        } catch (error) {
            return {
                success: false,
                error: {
                    name: t(
                        "notifications.forgotPassword.error",
                        "Forgot Password Error"
                    ),
                    message: t(
                        "notifications.forgotPassword.errorMessage",
                        "An unexpected error occurred. Please try again later."
                    ),
                },
            };
        }
    },
    updatePassword: async ({ key, password, t }) => {
        try {
            const response = await fetch(
                `${baseUrl}/api/_allauth/browser/v1/auth/password/reset`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCSRFToken(),
                    },
                    body: JSON.stringify({ key, password }),
                }
            );

            const data = await response.json();
            if (response.status === 200 || response.status === 401) {
                return {
                    success: true,
                    data: data,
                };
            } else {
                // Any other status is considered an error
                return {
                    success: false,
                    error: {
                        name: t(
                            "notifications.updatePassword.error",
                            "Password Reset Error"
                        ),
                        message:
                            data.detail ||
                            "An error occurred while resetting your password.",
                    },
                };
            }
        } catch (error) {
            return {
                success: false,
                error: {
                    name: t(
                        "notifications.updatePassword.error",
                        "Password Reset Error"
                    ),
                    message: t(
                        "notifications.updatePassword.errorMessage",
                        "An unexpected error occurred. Please try again later."
                    ),
                },
            };
        }
    },
};
