import {
    ChatItemProps,
    ChatMessage,
    ProChat,
    ProChatInstance,
} from "@ant-design/pro-chat";
import {
    useApiUrl,
    useCreate,
    useCustom,
    useInvalidate,
    useList,
    useOne,
} from "@refinedev/core";
import "./style.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { ISuggestion } from "types";
import { Locale } from "@ant-design/pro-chat/es/locale";
import { AssistantType } from "pages/assistants/types";
import { TOKEN_KEY } from "../../authProvider";
import { Select } from "antd/lib";
import { useSelect } from "@refinedev/antd";
import { Button, Col, Dropdown, Row, Space, Alert } from "antd";
import { PlusOutlined, CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { SuggestionComponent } from "./suggestions";
import { useTranslation } from "react-i18next";
import { ConsultingIntegrationModal } from "./consulting-integration";
import { useCurrentUserGroup } from "utilities/getCurrentUserGroup";
import { TFunction } from "i18next";

interface ActionsRenderProps {
    messageExtra: {
        props: {
            data: {
                id: string;
                role: string;
            };
        };
    };
}
const actionsRender = (
    props: ActionsRenderProps,
    openModal: (visible: boolean) => void,
    t: TFunction
): JSX.Element => {
    const items = [
        {
            key: "1",
            label: t(
                "assistant.consultingIntegration.confirmAnswer",
                "Confirm answer with a human"
            ),
            onClick: () => openModal(true),
        },
    ];

    const menuProps = {
        items,
    };

    const isDefaultMessage = props.messageExtra.props.data.id === "default";
    const isUserMessage = props.messageExtra.props.data.role === "user";

    if (isDefaultMessage || isUserMessage) {
        return <></>;
    }

    return (
        <div>
            <Dropdown
                placement="bottomRight"
                trigger={["click"]}
                menu={menuProps}
            >
                <Button
                    icon={<MoreOutlined />}
                    size="small"
                    className="w-6 h-6 p-0 flex items-center justify-center"
                />
            </Dropdown>
        </div>
    );
};

export enum ContentType {
    DOCUMENT = "document",
    ASSISTANT = "assistant",
}

const setChatIdInSessionStorage = (
    contentType: ContentType,
    contentId: number,
    chatId?: number
) => {
    const chatIdKey = "chatId";
    let chatIds = JSON.parse(sessionStorage.getItem(chatIdKey) || "{}");

    // Ensure chatIds is an object
    if (typeof chatIds !== "object" || chatIds === null) {
        chatIds = {};
    }

    // Ensure chatIds[contentType] is an object
    if (
        typeof chatIds[contentType] !== "object" ||
        chatIds[contentType] === null
    ) {
        chatIds[contentType] = {};
    }

    if (chatId !== undefined) {
        chatIds[contentType][contentId] = chatId;
    } else {
        delete chatIds[contentType][contentId];
    }

    sessionStorage.setItem(chatIdKey, JSON.stringify(chatIds));
};

const getChatIdFromSessionStorage = (
    contentType: ContentType,
    contentId: number
) => {
    const chatIdKey = "chatId";
    const chatIds = JSON.parse(sessionStorage.getItem(chatIdKey) || "{}");

    // Ensure chatIds is an object
    if (typeof chatIds !== "object" || chatIds === null) {
        return "";
    }

    // Ensure chatIds[contentType] is an object
    if (
        typeof chatIds[contentType] !== "object" ||
        chatIds[contentType] === null
    ) {
        return "";
    }

    return chatIds[contentType][contentId] || "";
};

const sendMessageToServer = async (
    apiUrl: string,
    assistantId: number = 0,
    assistantUuid: string | null = null,
    message: ChatMessage,
    contentType: ContentType,
    contentId?: number
) => {
    const meta =
        typeof message.content === "string"
            ? message.content.match(/<META>(.*)<\/META>/)
            : null;
    let action_type = null;
    let questionUuid = null;
    let answerUuid = null;
    if (meta) {
        const data = JSON.parse(meta[1]);
        action_type = data.actionType;
        questionUuid = data.questionUuid;
        answerUuid = data.answerUuid;
    }
    const data: {
        [key: string]: string | number | React.ReactNode | null;
        assistant_id: number;
        assistant_uuid: string | null;
        message: React.ReactNode;
        chat_message_history_id: number;
        action_type: "explain" | "hint" | null;
        question_uuid: string | null;
        answer_uuid: string | null;
        content_type: ContentType;
        content_id?: number | null;
    } = {
        assistant_id: assistantId,
        assistant_uuid: assistantUuid,
        message: message.content,
        chat_message_history_id: Number(
            getChatIdFromSessionStorage(contentType, contentId || 0)
        ),
        action_type: action_type,
        question_uuid: questionUuid,
        answer_uuid: answerUuid,
        content_type: contentType,
        content_id: contentId,
    };

    // throw away the null and undefined values
    Object.keys(data).forEach((key) => !data[key] && delete data[key]);
    const url = `${apiUrl}/assistant-stream/`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
        body: JSON.stringify(data),
    });
    const reader = response?.body?.getReader();
    const stream = new ReadableStream({
        start(controller) {
            function pump(): Promise<void | undefined> | undefined {
                return reader?.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                });
            }
            return pump();
        },
    });

    return new Response(stream);
};

interface IChatMessageHistory {
    id: number;
    name: string;
}

const SourceButtons = ({
    sources,
    jumpToPage,
    showPages = true,
    setAssistantVisible,
    isMobile,
}: {
    sources: { page: number; description: string }[];
    jumpToPage?: (page: number) => void;
    showPages?: boolean;
    setAssistantVisible?: (visible: boolean) => void;
    isMobile?: boolean;
}) => {
    if (!sources || sources.length === 0) return null;

    const { t } = useTranslation();

    return (
        <div className="mt-2">
            <span className="text-sm font-semibold mr-2">
                {t("assistant.sources", "Sources")}:
            </span>
            <Space wrap>
                {sources.map((source, index) => (
                    // <Tooltip key={index} title={source.description} placement="top">
                    <Button
                        size="small"
                        onClick={() => {
                            jumpToPage && jumpToPage(source.page - 1);
                            if (isMobile) {
                                setAssistantVisible?.(false);
                            }
                        }}
                        className="px-2 py-0 text-xs"
                    >
                        {showPages ? `P${source.page}` : index + 1}
                    </Button>
                    // </Tooltip>
                ))}
            </Space>
        </div>
    );
};

export const Assistant = ({
    assistantId,
    assistantUuid,
    contentType,
    contentId,
    setAssistantVisible,
    enableHideButton = false,
    jumpToPage,
    isMobile = false,
    isModal = false,
}: {
    assistantId: number;
    assistantUuid?: string;
    contentType: ContentType;
    contentId: number;
    setAssistantVisible?: (visible: boolean) => void;
    enableHideButton?: boolean;
    jumpToPage?: (page: number) => void;
    isMobile?: boolean;
    isModal?: boolean;
}) => {
    const apiUrl = useApiUrl();

    const { t } = useTranslation();

    const [selectedValue, setSelectedValue] = useState<{
        name: string;
        value: string | number;
    }>({
        name: t("assistant.newChat", "New Chat"),
        value: t("assistant.newChat", "New Chat"),
    });

    const { data: assistantData, isLoading: isAssistantLoading } =
        useOne<AssistantType>({
            resource: "assistants",
            id: assistantId,
        });

    const chatRef = useRef<ProChatInstance>();
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

    useEffect(() => {
        if (assistantData?.data?.initial_suggestions) {
            setSuggestions(assistantData.data.initial_suggestions);
        }
    }, [assistantData]);

    const localeMapper: { [key: string]: Locale } = {
        sl: "sl-SI",
        en: "en-US",
    };
    const locale = localeMapper[assistantData?.data?.language] || "en-US";
    const initialMessage =
        assistantData?.data?.initial_message ||
        t("assistant.initialMessage", "How can I help?");

    const currentChatId = getChatIdFromSessionStorage(contentType, contentId);

    const { selectProps: chatMessageHistorySelectProps, queryResult } =
        useSelect<IChatMessageHistory>({
            resource: "chat-message-histories",
            optionLabel: "name",
            optionValue: "id",
            pagination: {
                pageSize: 100,
            },
            filters: [
                {
                    field: "content_type",
                    operator: "eq",
                    value: contentType,
                },
                {
                    field: "content_id",
                    operator: "eq",
                    value: contentId,
                },
            ],
        });
    const currentChat = queryResult.data?.data?.find(
        (chat: IChatMessageHistory) => chat.id === Number(currentChatId)
    ) || { name: "New Chat", value: -1 };

    const { data: messagesData } = useList({
        resource: "chat-messages",
        queryOptions: {
            enabled: !!currentChatId,
        },
        filters: [
            {
                field: "chat_message_history_id",
                operator: "eq",
                value: currentChatId,
            },
        ],
    });

    // sort and memoize messages
    const sortedMessages = useMemo(() => {
        if (messagesData?.data) {
            return messagesData.data.sort(
                (a: { id: number }, b: { id: number }) => a.id - b.id
            );
        }
        return [];
    }, [messagesData]);

    useEffect(() => {
        if (queryResult.data?.data && currentChatId !== "") {
            setSelectedValue({
                name: currentChat?.name,
                value: currentChatId,
            });
        }
    }, [queryResult.data?.data]);

    const { mutate: mutateCreate } = useCreate<IChatMessageHistory>();
    const invalidate = useInvalidate();

    const newChat = async () => {
        mutateCreate(
            {
                resource: "chat-message-histories",
                values: {
                    assistant: assistantId,
                    content_type: contentType,
                    content_id: contentId,
                },
            },
            {
                onSuccess: (data: { data: { id: string; name: string } }) => {
                    invalidate({
                        resource: "chat-messages",
                        invalidates: ["list"],
                    });
                    invalidate({
                        resource: "chat-message-histories",
                        invalidates: ["list"],
                    });
                    setChatIdInSessionStorage(
                        contentType,
                        contentId,
                        Number(data?.data?.id)
                    );
                    // also set the chat id in the chat select
                    const sel = {
                        value: Number(data?.data?.id),
                        name: data?.data?.name,
                    };
                    setSelectedValue(sel);
                },
            }
        );
    };

    type MessageExtra = Record<string, never>;

    const onMessageOver = (messageExtra: MessageExtra) => {
        const latestChat = chatRef.current?.getChatMessages();
        if (latestChat && latestChat.length > 0) {
            const lastMessage = latestChat[latestChat.length - 1];
            chatRef.current?.setMessageValue(
                lastMessage.id,
                "extra",
                messageExtra
            );
        }
    };

    const ChatToolbar = () => {
        const SelectChat = () => {
            return (
                <Col className="flex grow min-w-0">
                    <Select
                        {...chatMessageHistorySelectProps}
                        size="middle"
                        rootClassName="grow min-w-0"
                        defaultValue={{
                            value: currentChat?.id,
                            name: currentChat?.name,
                            // @ts-expect-error Ignore this error
                            label: currentChat?.name,
                        }}
                        value={selectedValue}
                        onChange={(value) => setSelectedValue(value)}
                        onSelect={(value) => {
                            setChatIdInSessionStorage(
                                contentType,
                                contentId,
                                Number(value)
                            );
                            invalidate({
                                resource: "chat-messages",
                                invalidates: ["list"],
                            });
                        }}
                    />
                </Col>
            );
        };

        const NewChatButton = ({ isMini = false }: { isMini?: boolean }) => {
            return (
                <>
                    {currentChat?.value !== -1 && (
                        <Col className="flex">
                            <Button onClick={newChat} icon={<PlusOutlined />}>
                                {isMini
                                    ? t("assistant.new", "New")
                                    : t("assistant.newChat", "New Chat")}
                            </Button>
                        </Col>
                    )}
                </>
            );
        };

        const HideButton = ({ isMobile = false }: { isMobile?: boolean }) => {
            if (enableHideButton) {
                return (
                    <>
                        {isMobile ? (
                            <div className="flex justify-end">
                                <CloseOutlined
                                    onClick={() => setAssistantVisible?.(false)}
                                    className="text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
                                />
                            </div>
                        ) : (
                            <Col className="flex justify-end">
                                <CloseOutlined
                                    onClick={() => setAssistantVisible?.(false)}
                                    className="text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
                                />
                            </Col>
                        )}
                    </>
                );
            }
        };
        return (
            <>
                {!isMobile ? (
                    <>
                        <Row
                            gutter={[16, 16]}
                            justify="space-between"
                            align="middle"
                            className="w-[100%] pb-4 flex-shrink-0 flex-row-reverse flex-wrap-reverse"
                        >
                            <HideButton />
                            <NewChatButton />
                            <SelectChat />
                        </Row>
                    </>
                ) : (
                    <>
                        <Row
                            gutter={[16, 16]}
                            justify="space-between"
                            align="middle"
                            className="pb-4"
                        >
                            <SelectChat />
                            <NewChatButton isMini />
                            <div className="mr-4" />
                            <HideButton isMobile />
                        </Row>
                    </>
                )}
            </>
        );
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const ShortDisclaimer = () => {
        const { data: settings } = useCustom({
            url: `${apiUrl}/settings/`,
            method: "get",
        });

        const disclaimer = settings?.data?.disclaimer_short;
        const showAssistantDisclaimer =
            settings?.data?.show_assistant_disclaimer;

        if (showAssistantDisclaimer) {
            return (
                <span className="text-xs text-gray-400 p-1 flex items-center justify-center">
                    {disclaimer}
                </span>
            );
        }
        return null;
    };

    const { isOverUsageLimit } = useCurrentUserGroup();

    const UsageLimitOverlay = () => {
        if (!isOverUsageLimit) return null;

        return (
            <div
                className="absolute inset-0 bg-black bg-opacity-40 z-100 flex items-center justify-center rounded-lg"
                style={{
                    backdropFilter: "blur(4px)",
                }}
            >
                <Alert
                    message={t(
                        "assistant.usageLimitReached",
                        "Usage limit reached"
                    )}
                    description={t(
                        "assistant.usageLimitDescription",
                        "Your user group has reached its usage limit. Please contact your administrator."
                    )}
                    type="warning"
                    showIcon
                />
            </div>
        );
    };

    if (isAssistantLoading || queryResult.isLoading) {
        return null;
    }

    return (
        <div
            className="flex flex-col relative"
            style={{ height: isModal ? "40vh" : "78vh" }}
        >
            <ChatToolbar />
            <ConsultingIntegrationModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                chatMessageHistoryId={currentChatId}
            />
            <div className="flex-grow overflow-hidden relative">
                <ProChat
                    chatRef={chatRef}
                    style={{
                        maxWidth: "60rem",
                    }}
                    className="flex-grow h-full"
                    chatItemRenderConfig={{
                        actionsRender: (props: ChatItemProps) =>
                            // @ts-expect-error Ignore this type error
                            actionsRender(props, setIsModalVisible, t),
                    }}
                    initialChats={sortedMessages}
                    chats={sortedMessages}
                    showTitle={false}
                    helloMessage={initialMessage}
                    loading={false}
                    locale={locale}
                    actions={{
                        flexConfig: {},
                        render: undefined,
                    }}
                    messageItemExtraRender={(
                        message: ChatMessage,
                        type: "assistant" | "user"
                    ) => {
                        if (type === "assistant" && message.content) {
                            return (
                                <SourceButtons
                                    sources={message.extra?.sources}
                                    jumpToPage={jumpToPage}
                                    showPages={true}
                                    setAssistantVisible={setAssistantVisible}
                                    isMobile={isMobile}
                                />
                            );
                        }
                    }}
                    placeholder={
                        isOverUsageLimit
                            ? t(
                                  "assistant.usageLimitReached",
                                  "Usage limit reached"
                              )
                            : t("assistant.placeholder", "Ask me anything ...")
                    }
                    transformToChatMessage={(preChatMessage) => {
                        const lines = preChatMessage.split("\n");
                        const messages = lines
                            .map((line) => {
                                if (!line.trim() || line === "") return null;
                                const jsonValue = line
                                    .replace(/^data: /, "")
                                    .trim();
                                try {
                                    const data = JSON.parse(jsonValue);
                                    if (data.chat_message_history_id) {
                                        setChatIdInSessionStorage(
                                            contentType,
                                            contentId,
                                            data.chat_message_history_id
                                        );
                                    }
                                    if (data.message) {
                                        return data.message;
                                    }
                                    if (data.extra) {
                                        onMessageOver(data.extra);
                                    }
                                } catch (error) {
                                    console.error("Error parsing JSON:", error);
                                }
                                return null;
                            })
                            .filter(Boolean);

                        const messageContent = messages.join("");
                        return messageContent;
                    }}
                    userMeta={{
                        avatar: "",
                    }}
                    assistantMeta={{
                        avatar: assistantData?.data?.icon || "🤖",
                    }}
                    inputAreaProps={{
                        disabled: isOverUsageLimit,
                    }}
                    request={async (messages) => {
                        const latestMessage = messages[messages.length - 1];
                        const meta =
                            typeof latestMessage.content === "string"
                                ? latestMessage.content.match(
                                      /<META>(.*)<\/META>/
                                  )
                                : null;
                        if (meta) {
                            chatRef.current?.deleteMessage(latestMessage.id);
                        }
                        return sendMessageToServer(
                            apiUrl,
                            assistantId,
                            assistantUuid,
                            latestMessage,
                            contentType,
                            contentId
                        );
                    }}
                />
                {!isOverUsageLimit && (
                    <SuggestionComponent
                        suggestions={suggestions}
                        sendMessage={(msg) => {
                            if (!isOverUsageLimit) {
                                setSuggestions([]);
                                chatRef.current?.sendMessage(msg);
                            }
                        }}
                    />
                )}
                <UsageLimitOverlay />
            </div>
            <ShortDisclaimer />
        </div>
    );
};
