import { ConfigProvider, Button, ButtonProps } from 'antd';
import Color from 'color';

interface CustomButtonProps extends ButtonProps {
  override: string;
  hoverTransparency?: boolean;
  children: React.ReactNode;
}

const CustomButton: React.FC<CustomButtonProps> = ({children, override, hoverTransparency = false,  ...props}) => {
    const overrideColor = override || "#123123";
    const isDark = Color(overrideColor).isDark();
    const primaryColor = Color(overrideColor);
    const hoverColor = isDark ? primaryColor.lighten(0.2).hex() : primaryColor.lighten(0.2).hex();
    const activeColor = isDark ? primaryColor.darken(0.2).hex() : primaryColor.darken(0.2).hex();

    const modifiedTheme = {
        components: {
          Button: {
             colorPrimaryBorderHover: hoverColor,
             colorPrimaryHover: hoverTransparency ? `${hoverColor}55` : hoverColor,
             colorPrimary: overrideColor,
             colorPrimaryActive: hoverTransparency ? `${activeColor}55` : activeColor,
             colorPrimaryTextHover: hoverColor,
          }
        }
      };
    return (
        <ConfigProvider theme={modifiedTheme} >
            <Button type="primary" {...props}>{children}</Button>
        </ConfigProvider>
    );
};

export default CustomButton;
