import React from "react";
import { Form, FormProps, Input } from "antd";
import { useTranslate } from "@refinedev/core";

interface EvaluationQuestionSetFormProps {
  formProps: FormProps;
}

export const EvaluationQuestionSetForm: React.FC<EvaluationQuestionSetFormProps> = ({ formProps }) => {
    const t = useTranslate();

    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label={t("questionSets.fields.name", "Name")}
                name="name"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("questionSets.fields.description", "Description")}
                name="description"
            >
                <Input.TextArea />
            </Form.Item>
        </Form>
    );
};

export default EvaluationQuestionSetForm;
