import { HttpError, useCreate } from "@refinedev/core";
import React from "react";
import { Card, Row, Table, Tag, Typography } from "antd";
import { useTable, DateField } from "@refinedev/antd";
import { useDeleteMany } from "@refinedev/core";

import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
interface IRegistrationToken {
    id: number;
    token: string;
    user_group: number;
}

const CreateRegistrationTokenButton = ({
    userGroupId,
}: {
    userGroupId: number;
}) => {
    const { mutate: mutateCreate } = useCreate<IRegistrationToken>();

    const { t } = useTranslation();

    const onCreate = () => {
        mutateCreate({
            resource: "registration-tokens",
            values: {
                user_group: userGroupId,
            },
            meta: {
                userGroupId,
            },
        });
    };

    return (
        <Button icon={<PlusCircleOutlined />} onClick={onCreate} type="primary">
            {t("buttons.create", "Create")}
        </Button>
    );
};

export const RegistrationTokenTable = ({
    userGroupId,
    isEditable = false,
}: {
    userGroupId?: number;
    isEditable?: boolean;
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        []
    );
    const {
        mutate,
        isSuccess,
        isLoading: deleteManyIsLoading,
    } = useDeleteMany<IRegistrationToken>();

    const deleteSelectedItems = () => {
        mutate({
            resource: "registration-tokens",
            ids: selectedRowKeys.map(String),
            meta: {
                userGroupId,
            },
        });
    };

    React.useEffect(() => {
        if (isSuccess) {
            setSelectedRowKeys([]);
        }
    }, [isSuccess]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = isEditable
        ? {
              selectedRowKeys,
              onChange: onSelectChange,
              selections: [
                  Table.SELECTION_ALL,
                  Table.SELECTION_INVERT,
                  Table.SELECTION_NONE,
              ],
          }
        : undefined;
    const hasSelected = selectedRowKeys.length > 0;

    const { t } = useTranslation();

    const { tableProps } = useTable<IRegistrationToken, HttpError>({
        resource: "registration-tokens",
        meta: {
            userGroupId,
        },
        queryOptions: {
            enabled: !!userGroupId,
        },
    });

    return (
        <Card
            className="mt-4"
            styles={{
                body: {
                    padding: 0,
                },
            }}
            title={
                <>
                    <div className="flex justify-between items-center">
                        <span>
                            {t(
                                "registrationTokens.title",
                                "Registration Tokens"
                            )}
                        </span>
                        <div>
                            {isEditable && hasSelected && (
                                <Button
                                    className="mr-2"
                                    danger
                                    onClick={deleteSelectedItems}
                                    disabled={!hasSelected}
                                    loading={deleteManyIsLoading}
                                >
                                    {t("deleteSelectedItems", {
                                        count: selectedRowKeys.length,
                                    })}
                                </Button>
                            )}
                            {isEditable && userGroupId && (
                                <CreateRegistrationTokenButton
                                    userGroupId={Number(userGroupId)}
                                />
                            )}
                        </div>
                    </div>
                </>
            }
        >
            <Table {...tableProps} rowKey="id" rowSelection={rowSelection}>
                <Table.Column
                    dataIndex="id"
                    title={t("registrationTokens.fields.id", "ID")}
                />
                <Table.Column
                    dataIndex="token"
                    title={t("registrationTokens.fields.token", "Token")}
                />
                <Table.Column
                    dataIndex="is_used"
                    title={t("registrationTokens.fields.used", "Used")}
                    render={(is_used: boolean) =>
                        is_used ? (
                            <Tag color="red">{t("status.used", "Used")}</Tag>
                        ) : (
                            <Tag color="green">
                                {t("status.unused", "Unused")}
                            </Tag>
                        )
                    }
                />
                <Table.Column
                    dataIndex="created_at"
                    title={t(
                        "registrationTokens.fields.createdAt",
                        "Created At"
                    )}
                    render={(value) => (
                        new Date(value).toLocaleString("sl-SI")
                    )}
                />
            </Table>
        </Card>
    );
};
