import Color from "color";
import ColorButton from "components/ColorButton";
import { createPortal } from "react-dom";
import { ISuggestion } from "types";
import { useEffect, useState } from "react";

interface SuggestionProps {
    suggestions: ISuggestion[];
    sendMessage: (content: string) => void;
}

export const SuggestionComponent: React.FC<SuggestionProps> = ({
    suggestions,
    sendMessage,
}) => {
    const [actionBar, setActionBar] = useState<Element>(document.body);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const actionBarElement = document.querySelector(
                ".ant-pro-chat-input-area-action-bar"
            );
            // take the child ant-btn-icon-only from the action bar and remove it
            const child = actionBarElement?.querySelector(".ant-btn-icon-only");
            if (child) {
                child.remove();
            }
            if (actionBarElement) {
                setActionBar(actionBarElement);
                clearInterval(intervalId);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    if (!actionBar) {
        return null;
    }

    const backgroundColor = "#f0f2f5";

    const suggestionColor = Color(backgroundColor).isDark()
        ? Color(backgroundColor).lighten(1.6).hex()
        : Color(backgroundColor).darken(1.6).hex();

    const textColor = Color(suggestionColor).isDark() ? "white" : "black";

    // turn suggestions into buttons
    const suggestionButtons = suggestions.map((suggestion) => (
        <ColorButton
            hoverTransparency={true}
            key={suggestion.id}
            override={`${suggestionColor}55`}
            shape="round"
            onClick={(e) => {
                e.preventDefault();
                sendMessage(suggestion.content);
            }}
            size="middle"
            style={{
                fontSize: "15px",
                pointerEvents: "auto",
                color: textColor,
                whiteSpace: "normal",
                overflow: "hidden",
                display: "inline-block",
                width: "auto",
                height: "auto",
            }}
            type="primary"
            className="mr-1.5 mb-1.5 pb-0 pt-0"
            children={<p className="mb-0 no-underline">{suggestion.content}</p>}
        />
    ));

    return createPortal(
        <>
            <div style={{ flex: 1 }} />
            <div>{suggestionButtons}</div>
        </>,
        actionBar
    );
};
