import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { useGetIdentity } from "@refinedev/core";
import {
    Layout as AntdLayout,
    Popover,
    Space,
    Button,
    Switch,
    Typography,
    theme,
    Grid,
} from "antd";
import React, { useContext } from "react";
import { ColorModeContext } from "../../contexts/color-mode";
import { useThemedLayoutContext } from "@refinedev/antd";
import {
    SettingOutlined,
    LogoutOutlined,
    MenuOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const { useToken } = theme;
const { useBreakpoint } = Grid;

import {
    useTranslate,
    useWarnAboutChange,
    useLogout,
    useActiveAuthProvider,
} from "@refinedev/core";
import { AccountSettings } from "./account-settings";
import { CustomAvatar } from "../custom-avatar";
import { useNavigate } from "react-router-dom";

type IUser = {
    id: number;
    name: string;
    avatar: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
    sticky,
}) => {
    const { token } = useToken();
    const { data: user } = useGetIdentity<IUser>();
    const { mode, setMode } = useContext(ColorModeContext);

    const { mobileSiderOpen, setMobileSiderOpen } = useThemedLayoutContext();
    const screens = useBreakpoint();

    const headerStyles: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
    };

    const { t } = useTranslation();

    if (sticky) {
        headerStyles.position = "sticky";
        headerStyles.top = 0;
        headerStyles.zIndex = 1;
    }
    const navigate = useNavigate();
    const translate = useTranslate();

    const [opened, setOpened] = useState(false);
    const { warnWhen, setWarnWhen } = useWarnAboutChange();
    const authProvider = useActiveAuthProvider();
    const { mutate: mutateLogout } = useLogout({
        v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });
    const handleLogout = () => {
        if (warnWhen) {
            const confirm = window.confirm(
                translate(
                    "warnWhenUnsavedChanges",
                    "Are you sure you want to leave? You have unsaved changes."
                )
            );

            if (confirm) {
                setWarnWhen(false);
                mutateLogout();
            }
        } else {
            mutateLogout();
        }
    };

    const content = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div className="flex align-middle items-center justify-between">
                <Text
                    strong
                    style={{
                        padding: "12px 20px",
                    }}
                >
                    {user?.name}
                </Text>
                {/* TODO: Change this back when fixing darkmode */}
                {
                    !(
                        <Switch
                            checkedChildren="🌛"
                            unCheckedChildren="🔆"
                            onChange={() =>
                                setMode(mode === "light" ? "dark" : "light")
                            }
                            defaultChecked={mode === "dark"}
                            className="mr-4"
                        />
                    )
                }
            </div>
            <div
                style={{
                    borderTop: "1px solid #d9d9d9",
                    padding: "4px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                }}
            >
                <Button
                    style={{ textAlign: "left" }}
                    icon={<SettingOutlined />}
                    type="text"
                    block
                    onClick={() => setOpened(true)}
                >
                    {t("header.accountSettings", "Account settings")}
                </Button>
                <Button
                    style={{ textAlign: "left" }}
                    icon={<InfoCircleOutlined />}
                    type="text"
                    block
                    onClick={() => navigate('/information')}
                >
                    {t("header.about", "About")}
                </Button>
                <Button
                    style={{ textAlign: "left" }}
                    icon={<LogoutOutlined />}
                    type="text"
                    danger
                    block
                    onClick={() => handleLogout()}
                >
                    {t("header.logout", "Logout")}
                </Button>
            </div>
        </div>
    );

    return (
        <AntdLayout.Header style={headerStyles}>
            {!screens.lg && (
                <Button
                    type="text"
                    size="large"
                    onClick={() => setMobileSiderOpen(!mobileSiderOpen)}
                    icon={<MenuOutlined />}
                />
            )}
            <div style={{ flex: 1 }} />
            <Space>
                <Popover
                    placement="bottomRight"
                    content={content}
                    trigger="click"
                    overlayInnerStyle={{ padding: 0 }}
                    overlayStyle={{ zIndex: 999 }}
                >
                    <Space
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                        size="middle"
                    >
                        {user?.name && <Text strong>{user.name}</Text>}
                        <CustomAvatar
                            style={{
                                marginRight: "1rem",
                                flexShrink: 0,
                                fontSize: "20px",
                            }}
                            size={40}
                            src={user?.avatar}
                            name={user?.name}
                        />
                    </Space>
                </Popover>
                {user && (
                    <AccountSettings
                        opened={opened}
                        setOpened={setOpened}
                        userId={user.id}
                    />
                )}
            </Space>
        </AntdLayout.Header>
    );
};
