import React, { useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
    useInvalidate,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Spin, Typography } from "antd";
import { Evaluation } from "./types";
import { EvaluationAnswerTable } from "components/evaluation/evaluation-answer-table";
import { renderEvaluationStatusTag } from "components/evaluation/evaluation-status-tag";

const { Title, Text } = Typography;

export const EvaluationShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const t = useTranslate();
    const { queryResult } = useShow<Evaluation>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const invalidate = useInvalidate();

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (record?.status === "in_progress") {
            interval = setInterval(() => {
                invalidate({
                    resource: "evaluations",
                    id: record.id,
                    invalidates: ["detail"],
                });
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [record?.status, record?.id, invalidate]);

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>{t("evaluations.fields.id")}</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>{t("evaluations.fields.questionSet")}</Title>
            <Text>{record?.question_set_name}</Text>

            <Title level={5}>{t("evaluations.fields.assistant")}</Title>
            <Text>{record?.assistant_name}</Text>

            <Title level={5}>{t("evaluations.fields.document")}</Title>
            <Text>{record?.content_document_title}</Text>

            <Title level={5}>{t("evaluations.fields.status")}</Title>
            <Text>{renderEvaluationStatusTag(record?.status, translate)}</Text>

            <Title level={5}>{t("evaluations.fields.generatedGrade")}</Title>
            <Text>{record?.generated_grade ? `${record?.generated_grade.toFixed(1)}%` : "N/A"}</Text>

            <Title level={5}>{t("evaluations.fields.evaluationDuration")}</Title>
            <Text>{record?.evaluation_duration ? `${record?.evaluation_duration.toFixed(1)} ${t("evaluations.fields.seconds")}` : `${t("evaluations.fields.na")}`}</Text>

            <Title level={5}>{t("evaluations.fields.generatedSummary")}</Title>
            <div className="bg-gray-100 p-4 rounded-md mb-4">
                <ReactMarkdown>{record?.generated_summary || ''}</ReactMarkdown>
            </div>

            <Title level={5}>{t("evaluations.fields.answers")}</Title>
            {record?.status === "in_progress" ? (
                <div className="flex justify-center items-center h-10">
                    <Spin size="large" spinning />
                </div>
            ) : (
                <EvaluationAnswerTable />
            )}
        </Show>
    );
};

export default EvaluationShow;
