import React, { ReactElement } from "react";
import "../../components/markdown.css";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import {
    ToolbarProps,
    ToolbarSlot,
    TransformToolbarSlot,
    toolbarPlugin,
} from "@react-pdf-viewer/toolbar";
import { usePermissions, useShow } from "@refinedev/core";
import { ContentType } from "components/assistant";
import "./DocumentViewer.css";

type IContentDocument = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
};

import { ShowContentAssistant } from "components/layout/show-content-assistant";

export const ContentDocumentShow = () => {
    const { queryResult } = useShow<IContentDocument>();
    const { data, isLoading } = queryResult;
    const { data: permissionsData } = usePermissions({});

    // PDF navigator
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const highlightPluginInstance = highlightPlugin();
    const searchPluginInstance = searchPlugin();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const handleDocumentLoad = () => setDocumentLoaded(true);
    // const { highlight } = searchPluginInstance;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
        return {
            ...slot,
            Download: () => <></>,
            DownloadMenuItem: () => <></>,
            EnterFullScreen: () => <></>,
            EnterFullScreenMenuItem: () => <></>,
            SwitchTheme: () => <></>,
            SwitchThemeMenuItem: () => <></>,
            Open: () => <></>,
            Print: () => <></>,
            ShowProperties: () => <></>,
            ShowPropertiesMenuItem: () => <></>,
            OpenMenuItem: () => <></>,
            PrintMenuItem: () => <></>,
        };
    };

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar,
    });

    if (isLoading || !permissionsData) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <ShowContentAssistant
            title={record?.title}
            breadcrumb={""}
            contentType={ContentType.DOCUMENT}
            contentId={record.id}
            assistantId={record.assistant}
            assistantUuid={record.uuid}
            jumpToPage={jumpToPage}
        >
            {record.document && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={record.document}
                        defaultScale={0.8}
                        plugins={[
                            pageNavigationPluginInstance,
                            defaultLayoutPluginInstance,
                            highlightPluginInstance,
                            searchPluginInstance,
                        ]}
                        onDocumentLoad={handleDocumentLoad}
                    />
                </Worker>
            )}
            {!record.document && <div>No document uploaded</div>}
        </ShowContentAssistant>
    );
};
