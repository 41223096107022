import { RefineThemes } from "@refinedev/antd";
import { ConfigProvider, theme } from "antd";
import { PropsWithChildren, createContext, useEffect } from "react";
import { useCurrentTenant } from "../../tenants";

type ColorModeContextType = {
    mode: "light" | "dark" | undefined;
    setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
    {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const { color: tenantColor, cssFile, font: tenantFont } = useCurrentTenant();

    useEffect(() => {
        const link = document.createElement('link');
        link.href = cssFile;
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [cssFile]);

    const fontFamily = [
        tenantFont,
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(",");

    return (
        <ConfigProvider
            theme={{
                ...RefineThemes.Blue,
                algorithm: theme.defaultAlgorithm,
                token: {
                    colorPrimary: tenantColor,
                    fontFamily,
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};
