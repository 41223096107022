import { HttpError, useCreate } from "@refinedev/core";
import React from "react";
import { Card, Row, Table, Typography, Form, Space, Input } from "antd";
import {
    SaveButton,
    EditButton,
    TextField,
    useEditableTable,
    DateField,
} from "@refinedev/antd";
import { useDeleteMany } from "@refinedev/core";

import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
interface ISuggestion {
    id: string;
    content: string;
}

const CreateButton = ({ assistantId }: { assistantId: number }) => {
    const { mutate, isSuccess, isError, isLoading } = useCreate<ISuggestion>();

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (isSuccess || isError) {
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    }, [isSuccess, isError]);

    const handleCreateSuggestion = () => {
        mutate({
            resource: "initial-suggestions",
            meta: {
                assistantId,
            },
            values: {
                assistant: assistantId,
                content: "Change this Suggestion",
            },
        });
    };

    return (
        <Button
            icon={<PlusOutlined />}
            onClick={handleCreateSuggestion}
            type="primary"
            loading={isLoading}
        >
            {t("initialSuggestions.newSuggestion", "New Suggestion")}
        </Button>
    );
};

export const InitialSuggestionsTable = ({
    assistantId,
    isEditable = false,
}: {
    assistantId: number;
    isEditable?: boolean;
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        []
    );

    const {
        mutate,
        isSuccess,
        isLoading: deleteManyIsLoading,
    } = useDeleteMany<ISuggestion>();

    const deleteSelectedItems = () => {
        mutate({
            resource: "initial-suggestions",
            meta: {
                assistantId,
            },
            ids: selectedRowKeys.map(String),
        });
    };

    React.useEffect(() => {
        if (isSuccess) {
            setSelectedRowKeys([]);
        }
    }, [isSuccess]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = isEditable
        ? {
              selectedRowKeys,
              onChange: onSelectChange,
              selections: [
                  Table.SELECTION_ALL,
                  Table.SELECTION_INVERT,
                  Table.SELECTION_NONE,
              ],
          }
        : undefined;
    const hasSelected = selectedRowKeys.length > 0;

    const {
        tableProps,
        formProps,
        isEditing,
        setId: setEditId,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
    } = useEditableTable<ISuggestion, HttpError>({
        resource: "initial-suggestions",
        meta: {
            assistantId,
        },
    });

    const { t } = useTranslation();

    if (!assistantId) {
        return null;
    }

    return (
        <Card className="mt-4">
            <Row justify="space-between" className="mb-4">
                <Typography.Title level={4}>
                    {t("initialSuggestions.title", "Initial Suggestions")}
                </Typography.Title>
                <div className="flex-grow" />
                {isEditable && hasSelected && (
                    <Button
                        className="mr-2"
                        danger
                        onClick={deleteSelectedItems}
                        disabled={!hasSelected}
                        loading={deleteManyIsLoading}
                    >
                        {`${t("initialSuggestions.delete", "Delete")} ${
                            selectedRowKeys.length
                        } ${t("initialSuggestions.items", "items")}`}
                    </Button>
                )}
                {isEditable && (
                    <CreateButton
                        assistantId={assistantId as unknown as number}
                    />
                )}
            </Row>
            <Form {...formProps}>
                <Table
                    {...tableProps}
                    rowKey="id"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onRow={(record: any) => ({
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onClick: (event: any) => {
                            if (event.target.nodeName === "TD") {
                                setEditId && setEditId(record.id);
                            }
                        },
                    })}
                    rowSelection={rowSelection}
                >
                    <Table.Column
                        dataIndex="id"
                        title={t("initialSuggestions.fields.id", "ID")}
                    />
                    <Table.Column<ISuggestion>
                        dataIndex="content"
                        title={t("initialSuggestions.fields.content", "Content")}
                        render={(value, record) => {
                            if (record.id && isEditing(record.id)) {
                                return (
                                    <Form.Item
                                        name="content"
                                        style={{ margin: 0 }}
                                    >
                                        <Input />
                                    </Form.Item>
                                );
                            }
                            return <TextField value={value} />;
                        }}
                    />
                    <Table.Column
                        dataIndex="created_at"
                        title={t("initialSuggestions.fields.createdAt", "Created At")}
                        render={(value) => (
                            <DateField
                                value={value}
                                format="MMMM Do YYYY, hh:mm:ss"
                            />
                        )}
                    />
                    {isEditable && (
                        <Table.Column<ISuggestion>
                            title={t("initialSuggestions.fields.actions", "Actions")}
                            dataIndex="actions"
                            align="right"
                            render={(_, record) => {
                                if (record.id && isEditing(record.id)) {
                                    return (
                                        <Space>
                                            <SaveButton
                                                {...saveButtonProps}
                                                hideText
                                                size="small"
                                            />
                                            <Button
                                                {...cancelButtonProps}
                                                size="small"
                                            >
                                                {t(
                                                    "initialSuggestions.actions.cancel", "Cancel"
                                                )}
                                            </Button>
                                        </Space>
                                    );
                                }
                                return (
                                    <EditButton
                                        {...editButtonProps(record.id || "")}
                                        hideText
                                        size="small"
                                    />
                                );
                            }}
                        />
                    )}
                </Table>
            </Form>
        </Card>
    );
};
