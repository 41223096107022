import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { EvaluationQuestionSetForm } from "./form";
import { EvaluationQuestionTable } from "components/evaluation/evaluation-question-table";

export const EvaluationQuestionSetEdit: React.FC<
    IResourceComponentsProps
> = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <EvaluationQuestionSetForm formProps={formProps} />
            <EvaluationQuestionTable />
        </Edit>
    );
};

export default EvaluationQuestionSetEdit;
