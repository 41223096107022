import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import {
    useApiUrl,
    useCustom,
    useGetIdentity,
    useTranslate,
    useUpdate,
} from "@refinedev/core";
import { useCurrentTenant } from "tenants";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

const FirstTimeInformationPopup: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const t = useTranslate();

    const { data: user } = useGetIdentity();

    const apiUrl = useApiUrl();
    const { data: settings, isLoading } = useCustom({
        url: `${apiUrl}/settings/`,
        method: "get",
        queryOptions: {
            enabled: !!user?.user_group,
        },
    });

    const { title } = useCurrentTenant();

    const { mutate } = useUpdate();

    useEffect(() => {
        if (
            settings?.data?.first_time_information_popup_text &&
            user?.show_first_time_information_popup
        ) {
            setIsModalVisible(true);
        }
    }, [settings, user]);

    const handleOk = () => {
        setIsModalVisible(false);
        updateSettings();
    };

    const updateSettings = () => {
        mutate({
            resource: "users",
            id: user?.id,
            values: {
                show_first_time_information_popup: false,
            },
            successNotification: false,
        });
    };

    if (isLoading) return null;

    return (
        <Modal
            title={t("firstTimePopup.title", "Welcome to {{title}}!", {
                title,
            })}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            closable
            maskClosable={false}
            width="80%"
            footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                    {t("firstTimePopup.gotIt", "Got it!")}
                </Button>,
            ]}
        >
            <div className="w-full min-h-[25%] max-h-[80%]">
                <div className="markdown-body">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                        {settings?.data?.first_time_information_popup_text}
                    </ReactMarkdown>
                </div>
            </div>
        </Modal>
    );
};

export default FirstTimeInformationPopup;
