import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import {
    List,
    useTable,
    EditButton,
    ShowButton,
    DeleteButton,
} from "@refinedev/antd";
import { Breadcrumb, Table } from "antd";
import { EvaluationQuestionSet } from "./types";
import { Link } from "react-router-dom";

export const EvaluationQuestionSetList: React.FC<
    IResourceComponentsProps
> = () => {
    const t = useTranslate();
    const { tableProps } = useTable<EvaluationQuestionSet>({
        syncWithLocation: true,
    });

    const breadcrumbItems = [
        {
            title: <Link to="/evaluations">{t("evaluations.title")}</Link>,
        },
        {
            title: t("questionSets.title"),
        },
    ];

    return (
        <List breadcrumb={<Breadcrumb items={breadcrumbItems} />}>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={t("questionSets.fields.id")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("questionSets.fields.name")}
                />
                <Table.Column
                    dataIndex="description"
                    title={t("questionSets.fields.description")}
                />
                <Table.Column
                    dataIndex="questions"
                    title={t("questionSets.fields.questionCount")}
                    render={(questions) => questions.length}
                />
                <Table.Column
                    title={t("table.actions")}
                    dataIndex="actions"
                    align="end"
                    render={(_, record: EvaluationQuestionSet) => (
                        <>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                className="mr-1"
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                className="mr-1"
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </>
                    )}
                />
            </Table>
        </List>
    );
};

export default EvaluationQuestionSetList;
