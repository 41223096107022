import React, { useState } from "react";
import { Table, Button, Modal, Form, Input, message, Card, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useList, useCreate } from "@refinedev/core";
import { useCurrentTenant } from "tenants";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { PlusOutlined } from "@ant-design/icons";

interface InvitedUsersTableProps {
    userGroupId: number;
    isEditable: boolean;
}

export const InvitedUsersTable: React.FC<InvitedUsersTableProps> = ({
    userGroupId,
    isEditable,
}) => {
    const { t, i18n } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [emails, setEmails] = useState<string[]>([]);

    const { data, isLoading } = useList({
        resource: `invitations`,
        queryOptions: {
            enabled: !!userGroupId,
        },
        filters: [
            {
                field: "user_group",
                operator: "eq",
                value: userGroupId,
            },
        ],
    });

    const { mutate: inviteUsers } = useCreate();

    const columns = [
        { title: t("invitedUsers.email"), dataIndex: "email", key: "email" },
        {
            title: t("invitedUsers.accepted"),
            dataIndex: "accepted",
            key: "accepted",
            render: (accepted: boolean) => (
                <Tag color={accepted ? "green" : "red"}>
                    {accepted ? t("yes") : t("no")}
                </Tag>
            ),
        },
        {
            title: t("invitedUsers.invitedAt"),
            dataIndex: "sent",
            key: "sent",
            render: (sent: string) =>
                sent
                    ? new Date(sent).toLocaleString("sl-SI")
                    : t("invitedUsers.notSentYet"),
        },
    ];

    const handleInvite = () => {
        form.validateFields().then((values) => {
            inviteUsers(
                {
                    resource: "invitations",
                    values: {
                        user_group: userGroupId,
                        emails: emails,
                        message: values.message,
                        language: i18n.language,
                    },
                },
                {
                    onSuccess: () => {
                        setIsModalVisible(false);
                        form.resetFields();
                        setEmails([]);
                        message.success(t("invitedUsers.inviteSent"));
                    },
                    onError: (error: any) => {
                        message.error(
                            error?.response?.data?.error ||
                                t("invitedUsers.inviteError")
                        );
                    },
                }
            );
        });
    };

    const { title } = useCurrentTenant();

    return (
        <Card
            className="mt-4"
            styles={{
                body: {
                    padding: 0,
                }
            }}
            title={
                <>
                    <div className="flex justify-between items-center">
                        <span>
                            {t(
                                "userGroupForm.fields.invitedUsers",
                                "Invited Users"
                            )}
                        </span>
                        {isEditable && (
                            <Button
                                onClick={() => setIsModalVisible(true)}
                                type="primary"
                                size="middle"
                                icon={<PlusOutlined />}
                            >
                                {t("invitedUsers.invite")}
                            </Button>
                        )}
                    </div>
                </>
            }
        >
            <Table
                dataSource={data?.data}
                columns={columns}
                loading={isLoading}
                rowKey="id"
            />
            <Modal
                title={t("invitedUsers.inviteModalTitle")}
                open={isModalVisible}
                onOk={handleInvite}
                onCancel={() => {
                    setIsModalVisible(false);
                    setEmails([]);
                    form.resetFields();
                }}
                okText={t("invitedUsers.invite")}
                cancelText={t("invitedUsers.cancel")}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="emails"
                        label={t("invitedUsers.emailsLabel")}
                        rules={[
                            {
                                required: true,
                                message: t("invitedUsers.emailsRequired"),
                            },
                        ]}
                    >
                        <ReactMultiEmail
                            placeholder={t("invitedUsers.emailsPlaceholder")}
                            emails={emails}
                            onChange={(_emails: string[]) => {
                                setEmails(_emails);
                            }}
                            getLabel={(email, index, removeEmail) => (
                                <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span
                                        data-tag-handle
                                        onClick={() => removeEmail(index)}
                                    >
                                        ×
                                    </span>
                                </div>
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label={t("invitedUsers.messageLabel")}
                    >
                        <Input.TextArea
                            placeholder={t(
                                "invitedUsers.messagePlaceholder",
                                "Hello, you are invited to join {{title}}. \n\nPlease click the link to accept the invitation and complete the registration process.",
                                {
                                    title,
                                }
                            )}
                            rows={5}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
};
