import { Create, useForm } from "@refinedev/antd";
import { UserForm } from "./form";

export const UserCreate = () => {
    const { formProps, saveButtonProps } = useForm({});

    return (
        <Create saveButtonProps={saveButtonProps}>
            <UserForm formProps={formProps} data={undefined} />
        </Create>
    );
};
