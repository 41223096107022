import { ReactNode } from "react";

export enum DateRange {
    LastMonth = "lastMonth",
    LastWeek = "lastWeek",
    Custom = "custom",
}

export interface InfoCardProps {
    title: string;
    value: string | number;
    change: number;
    icon?: ReactNode;
    color: string;
    dateRange: DateRange;
}

export interface IMessage {
    id: number;
    role: string;
    content: string;
}

export interface IdentityData {
    user_group?: {
        id?: string;
        name?: string;
    };
}
