import { Show } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { Typography, List, Card } from "antd";
import { useParams } from "react-router-dom";
import moment from 'moment/min/moment-with-locales';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

// Step 1: Define an interface for the message data
interface MessageItem {
    id: string;
    created_at: string;
    content: string;
    role: 'user' | 'assistant';
}

export const ChatMessageHistoryShow = () => {
    const { id: currentChatId } = useParams<{ id: string }>();
    const { data: messagesData, isLoading } = useList<MessageItem>({
        resource: "chat-messages",
        queryOptions: {
            enabled: !!currentChatId,
        },
        filters: [
            {
                field: "chat_message_history_id",
                operator: "eq",
                value: currentChatId,
            },
        ],
        pagination: {
            pageSize: 500,
        },
    });
    const { t } = useTranslation();

    return (
        <Show isLoading={isLoading} title={t("chatMessageHistory.conversation", "Conversation")}>
            <List<MessageItem> // Use the interface here
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
                dataSource={messagesData?.data.sort(
                    (a: MessageItem, b: MessageItem) =>
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime()
                )}
                renderItem={(item) => (
                    <div style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "flex-end" }}>
                        <List.Item
                            key={item.id}
                            style={{
                                width: "80%",
                                alignSelf: item.role === "user" ? "flex-start" : "flex-end",
                                marginLeft: item.role === "user" ? "0%" : "20%",
                            }}
                        >
                            <Card
                                style={{
                                    width: "100%",
                                }}
                                title={
                                    <Title level={5}>{t(`chatMessageHistory.messageFrom.${item.role}`, `Message from ${item.role}`)}</Title>
                                }
                                extra={
                                    <Text type="secondary">
                                        {moment(item.created_at).format(t("chatMessageHistory.dateFormat", "MMM DD, YYYY hh:mm A"))}
                                    </Text>
                                }
                            >
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                    {item.content}
                                </ReactMarkdown>
                            </Card>
                        </List.Item>
                    </div>
                )}
            />
        </Show>
    );
};
