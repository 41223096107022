import { Edit, useForm } from "@refinedev/antd";
import { AssistantType } from "./types";

import { AssistantForm } from "./form";
import { InitialSuggestionsTable } from "./initial-suggestion-table";
import { Collapse } from "antd";
import { useTranslate } from "@refinedev/core";
// import { DocumentTable } from "./document-table";

export const AssistantEdit = () => {
    const { formProps, queryResult, saveButtonProps } = useForm();

    const t = useTranslate();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <AssistantForm
                data={queryResult?.data?.data as AssistantType}
                formProps={formProps}
            />
            <Collapse>
                <Collapse.Panel header={t("assistants.suggestions", "Suggestions")} key="1">
                    <InitialSuggestionsTable
                        assistantId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                </Collapse.Panel>
            </Collapse>
            {/* <DocumentTable assistantId={parseInt(queryResult?.data?.data?.id)} isEditable /> */}
        </Edit>
    );
};
