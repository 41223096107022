import React, { useState, useEffect } from "react";
import { useGetIdentity } from "@refinedev/core";
import { List, CreateButton } from "@refinedev/antd";
import { Radio } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { IIdentity } from "../../authProvider";
import { useTranslation } from "react-i18next";

interface ContentListProps<T> {
  cardComponent: React.FC<{ data: T[] }>;
  tableComponent: React.FC;
  cookieKey: string;
}

export const ContentList = <T,>({
  cardComponent: CardComponent,
  tableComponent: TableComponent,
  cookieKey,
}: ContentListProps<T>) => {
  const { data: identity } = useGetIdentity<IIdentity>();
  const [showCards, setShowCards] = useState(() => {
    const savedView = Cookies.get(cookieKey);
    return savedView ? savedView === "cards" : true;
  });

  useEffect(() => {
    Cookies.set(cookieKey, showCards ? "cards" : "table", { expires: 365 });
  }, [showCards, cookieKey]);

  const { t } = useTranslation();

  if (!identity) {
    return <div>Loading...</div>;
  }

  const isSuperuserNotInRegularMode =
    identity.is_superuser && !identity.regular_user_mode;

  return (
    <List
      canCreate={false}
      headerButtons={
        isSuperuserNotInRegularMode && (
          <>
            <Radio.Group
              value={showCards ? "cards" : "table"}
              onChange={(e) => setShowCards(e.target.value === "cards")}
              buttonStyle="solid"
              className="mr-2 flex items-center justify-center"
            >
              <Radio.Button value="cards" className="flex items-center justify-center">
                <AppstoreOutlined className="flex-shrink-0" />
                <span className="hidden sm:inline ml-2">{t("gallery", "Gallery")}</span>
              </Radio.Button>
              <Radio.Button value="table" className="flex items-center justify-center">
                <BarsOutlined className="flex-shrink-0" />
                <span className="hidden sm:inline ml-2">{t("list", "List")}</span>
              </Radio.Button>
            </Radio.Group>
            <CreateButton />
          </>
        )
      }
    >
      {isSuperuserNotInRegularMode && !showCards ? (
        <TableComponent />
      ) : (
        <CardComponent data={[]} />
      )}
    </List>
  );
};
