import {
    DatePicker,
    Form,
    FormProps,
    Input,
    InputNumber,
    Switch,
    Upload,
} from "antd";
import { UserGroupData } from "./types";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { getValueFromEvent } from "@refinedev/antd";
import { useCurrentTenant } from "tenants";
import { Feature } from "utilities/availableFeatures";
import { useState } from "react";
import { Collapse } from "antd";
import dayjs from "dayjs";
export const UserGroupForm = ({
    formProps,
    data,
}: {
    formProps: FormProps;
    data: UserGroupData | undefined;
}) => {
    const { t } = useTranslation();
    const { features } = useCurrentTenant();

    const showGroupCustomization = features.includes(
        Feature.GroupBasedCustomization
    );

    const [removeLogo, setRemoveLogo] = useState(false);

    return (
        <Form
            {...formProps}
            layout="vertical"
            onFinish={async (values) => {
                const formattedValues = {
                    ...values,
                };
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { logo_images, ...otherValues } = formattedValues as any;
                const logo_image =
                    (logo_images &&
                        logo_images[0] &&
                        logo_images[0].originFileObj) ||
                    null;
                if (logo_image) {
                    otherValues.logo_image = logo_image;
                }
                if (removeLogo) {
                    otherValues.logo_image = null;
                }
                return formProps.onFinish?.(formattedValues);
            }}
        >
            <Form.Item
                label={t("userGroupForm.fields.name", "Name")}
                name={["name"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Collapse className="mb-4">
                <Collapse.Panel
                    header={t(
                        "userGroupForm.fields.usageControls",
                        "Usage Controls"
                    )}
                    key="1"
                >
                    <Form.Item
                        name="usage_limit_enabled"
                        required
                        label={t(
                            "info.edit.usageLimitEnabled",
                            "Enable Usage Limit"
                        )}
                        extra={t("info.edit.usageLimitEnabledExtra")}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label={t(
                            "userGroupForm.fields.monthlyUsageLimit",
                            "Monthly Usage Limit"
                        )}
                        name={["monthly_usage_limit"]}
                        rules={[
                            {
                                required: true,
                                min: 0,
                                max: 10000,
                                type: "number",
                            },
                        ]}
                        initialValue={500}
                    >
                        <InputNumber />
                    </Form.Item>
                </Collapse.Panel>
            </Collapse>

            <Collapse className="mb-4">
                <Collapse.Panel
                    header={t(
                        "userGroupForm.fields.trialAndExpiration",
                        "Trial and Expiration"
                    )}
                    key="2"
                >
                    <Form.Item
                        label={t("userGroupForm.fields.isTrial", "Is Trial")}
                        name={["is_trial"]}
                        rules={[{ required: false }]}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label={t(
                            "userGroupForm.fields.subscriptionExpirationDate",
                            "Subscription Expiration Date"
                        )}
                        name={["subscription_expiration_date"]}
                        rules={[{ required: false }]}
                        getValueProps={(value) => ({
                            value: value ? dayjs(value) : "",
                          })}
                    >
                        <DatePicker format="DD.MM.YYYY" />
                    </Form.Item>
                </Collapse.Panel>
            </Collapse>

            {showGroupCustomization && (
                <Collapse className="mb-4">
                    <Collapse.Panel
                        header={t(
                            "userGroupForm.optionalCustomization",
                            "Customization (Optional)"
                        )}
                        key="1"
                    >
                        <Form.Item
                            label={t(
                                "userGroupForm.fields.displayName",
                                "Display Name"
                            )}
                            name={["group_display_name"]}
                            rules={[{ required: false }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t(
                                "userGroupForm.fields.productName",
                                "Product Name"
                            )}
                            name={["product_name"]}
                            rules={[{ required: false }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t(
                                "userGroupForm.fields.logo_image",
                                "Logo image"
                            )}
                        >
                            <Form.Item
                                name="logo_images"
                                valuePropName="file"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={
                                        data?.logo_image
                                            ? [
                                                  {
                                                      uid: "-1",
                                                      name:
                                                          data?.logo_label ||
                                                          "",
                                                      status: "done",
                                                      url:
                                                          data.logo_image || "",
                                                  },
                                              ]
                                            : []
                                    }
                                    onRemove={() => setRemoveLogo(true)}
                                    beforeUpload={() => false}
                                >
                                    <p className="ant-upload-text">
                                        {t(
                                            "userGroupForm.fields.dragDropText",
                                            "Drag & drop logo here"
                                        )}
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Collapse.Panel>
                </Collapse>
            )}
        </Form>
    );
};
