import { Create, useForm } from "@refinedev/antd";

import { AssistantForm } from "./form";

export const AssistantCreate = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <AssistantForm data={undefined} formProps={formProps} />
        </Create>
    );
};
