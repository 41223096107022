import {
    DeleteButton,
    Edit,
    EditButton,
    SaveButton,
    ShowButton,
    useForm,
    useTable,
} from "@refinedev/antd";
import { Card, Collapse, Space, Table } from "antd";
import { UserGroupForm } from "./form";
import { UserGroupData } from "./types";
import { RegistrationTokenTable } from "components/registration-tokens/table";
import AccessTable from "components/access/table";
import { BaseRecord, CrudFilters } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import { InvitedUsersTable } from "components/user-groups/user-invites";

export const UserGroupUsers = ({ userGroupId }: { userGroupId: number }) => {
    const { t } = useTranslation();

    const { tableProps } = useTable({
        syncWithLocation: true,
        resource: "users",
        filters: {
            permanent: [
                {
                    field: "user_group",
                    operator: "eq",
                    value: userGroupId,
                },
            ] as CrudFilters,
        },
    });

    return (
        <Card
            title={t("userGroupForm.fields.allGroupUsers", "All Group Users")}
            styles={{
                body: {
                    padding: 0,
                },
            }}
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={t("tableHeaders.id", "ID")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("tableHeaders.name", "Name")}
                />
                <Table.Column
                    dataIndex="email"
                    title={t("tableHeaders.email", "Email")}
                />
                <Table.Column
                    dataIndex="usage"
                    title={t("tableHeaders.usage", "Usage")}
                />
                <Table.Column
                    title={t("tableHeaders.actions", "Actions")}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => {
                        return (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    resource="users"
                                    recordItemId={record.id}
                                />
                                <ShowButton
                                    hideText
                                    size="small"
                                    resource="users"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    resource="users"
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </Card>
    );
};

export const UserGroupEdit = () => {
    const { formProps, saveButtonProps, queryResult } = useForm(
        {
            redirect: false,

        }
    );

    const { t } = useTranslation();

    return (
        <Edit
        footerButtons={[]}
        headerButtons={[
            <DeleteButton key="delete-button" />,
            <SaveButton {...saveButtonProps} />,
        ]}
        >
            <UserGroupForm
                formProps={formProps}
                data={queryResult?.data?.data as UserGroupData | undefined}
            />
            <Collapse className="mb-4">
                <Collapse.Panel
                    key="1"
                    header={t(
                        "userGroupForm.fields.userManagement",
                        "User Management"
                    )}
                >
                    <UserGroupUsers
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                    />
                    <InvitedUsersTable
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                    <RegistrationTokenTable
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                </Collapse.Panel>
            </Collapse>
            <Collapse>
                <Collapse.Panel
                    key="2"
                    header={t(
                        "userGroupForm.fields.availableFeatures",
                        "Available Features"
                    )}
                >
                    <AccessTable
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                </Collapse.Panel>
            </Collapse>
        </Edit>
    );
};
