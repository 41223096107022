import { Collapse, Form, Input, Select, Upload } from "antd";
import { ContentDocumentType } from "./types";
import { getValueFromEvent, useSelect } from "@refinedev/antd";
import { FormProps } from "antd/lib";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const ContentDocumentForm = ({
    data,
    formProps,
}: {
    data?: ContentDocumentType;
    formProps: FormProps;
}) => {
    const { selectProps: assistantSelectProps } = useSelect({
        resource: "assistants",
        optionLabel(item) {
            return item?.name;
        },
    });

    const [documentToDelete, setDocumentToDelete] = useState(false);
    const [thumbnailToDelete, setThumbnailToDelete] = useState(false);

    const { t } = useTranslation();

    if (!formProps) {
        return null;
    }

    return (
        <Form
            {...formProps}
            layout="vertical"
            initialValues={data}
            onFinish={async (values) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { documents, thumbnails, ...otherValues } = values as any;
                const document =
                    (documents && documents[0] && documents[0].originFileObj) ||
                    null;
                if (document) {
                    otherValues.document = document;
                }
                const thumbnail =
                    (thumbnails &&
                        thumbnails[0] &&
                        thumbnails[0].originFileObj) ||
                    null;
                if (thumbnail) {
                    otherValues.thumbnail = thumbnail;
                }

                if (thumbnail && thumbnail[0] && thumbnail[0].originFileObj) {
                    otherValues.thumbnail = thumbnail[0].originFileObj;
                } else if (thumbnailToDelete) {
                    otherValues.thumbnail = null;
                }

                if (document && document[0] && document[0].originFileObj) {
                    otherValues.document = document[0].originFileObj;
                } else if (documentToDelete) {
                    otherValues.document = null;
                }

                return formProps.onFinish?.({
                    ...otherValues,
                });
            }}
        >
            <Form.Item
                label={t("content-documents.fields.title", "Title")}
                name={["title"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("content-documents.fields.assistant", "Assistant")}
                name={["assistant"]}
                rules={[{ required: true }]}
            >
                <Select {...assistantSelectProps} />
            </Form.Item>
            <Form.Item
                label={t("content-documents.fields.document", "Document")}
            >
                <Form.Item
                    name="documents"
                    valuePropName="file"
                    getValueFromEvent={getValueFromEvent}
                >
                    <Upload.Dragger
                        listType="picture"
                        maxCount={1}
                        defaultFileList={
                            data?.document && !documentToDelete
                                ? [
                                      {
                                          uid: "-1",
                                          name: data?.document_label || "",
                                          status: "done",
                                          url: data?.document || "",
                                      },
                                  ]
                                : []
                        }
                        onRemove={() => setDocumentToDelete(true)}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-text">
                            {t(
                                "content-documents.dragDropText.document",
                                "Drag & drop document here"
                            )}
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <Collapse>
                <Collapse.Panel
                    header={t("content-documents.fields.advanced", "Advanced")}
                    key="1"
                >
                        <Form.Item
                            label={t(
                                "content-documents.fields.thumbnail",
                                "Thumbnail"
                            )}
                        >
                            <Form.Item
                                name="thumbnails"
                                valuePropName="file"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={
                                        data?.thumbnail && !thumbnailToDelete
                                            ? [
                                                  {
                                                      uid: "-1",
                                                      name:
                                                          data?.thumbnail_label ||
                                                          "",
                                                      status: "done",
                                                      url:
                                                          data?.thumbnail || "",

                                                  },
                                              ]
                                            : []
                                    }
                                    onRemove={() => setThumbnailToDelete(true)}
                                    beforeUpload={() => false}
                                >
                                    <p className="ant-upload-text">
                                        {t(
                                            "content-documents.dragDropText.thumbnail",
                                            "Drag & drop thumbnail here"
                                        )}
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Collapse.Panel>
                </Collapse>
            </Form.Item>
        </Form>
    );
};
