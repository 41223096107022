import { Edit, useForm } from "@refinedev/antd";
import { UserForm } from "./form";
import { UserData } from "./types";

export const UserEdit = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm(
        {}
    );

    return (
        <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
            <UserForm
                formProps={formProps}
                data={queryResult?.data?.data as UserData | undefined}
            />
        </Edit>
    );
};
