import React from "react";
import { useList, useGetIdentity } from "@refinedev/core";
import { Table, Card, Row, Col, Space, Tag } from "antd";
import {
    DeleteButton,
    EditButton,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContentList } from "components/layout/content-list";
import { IIdentity } from "../../authProvider";
import { ContentDocumentType } from "./types";
import { LockOutlined } from "@ant-design/icons";

const ContentDocumentCardList: React.FC = () => {
    const { data, isLoading } = useList<ContentDocumentType>({
        resource: "content-documents",
    });

    const { t } = useTranslation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-4">
            <Row gutter={[16, 16]}>
                {data?.data.map((document: ContentDocumentType) => {
                    const hasTrial = document.has_trial;
                    const trialExpirationDate = document.trial_expiration_date;
                    const hasExpired =
                        trialExpirationDate &&
                        new Date(trialExpirationDate) < new Date();

                    return (
                        <Col
                            key={document.id}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            <div className="relative">
                                <Card
                                    hoverable
                                    cover={
                                        <>
                                            <img
                                                alt={document.title}
                                                src={
                                                    document.thumbnail ||
                                                    `https://picsum.photos/seed/${document.id}/300/200`
                                                }
                                                className={`h-90 object-cover ${
                                                    hasExpired
                                                        ? "filter blur-sm"
                                                        : ""
                                                }`}
                                            />
                                            {hasExpired && (
                                                <>
                                                    <div
                                                        className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center flex-col"
                                                        style={{
                                                            backdropFilter:
                                                                "blur(2px)",
                                                            borderRadius:
                                                                "0.5rem",
                                                        }}
                                                    >
                                                        <div className="flex flex-col items-center justify-center h-full">
                                                            <LockOutlined className="text-white text-4xl" />
                                                            <p className="text-white text-lg mt-2">
                                                                {t(
                                                                    "document.trial.expired",
                                                                    "Trial expired"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    }
                                    className="h-full"
                                >
                                    <Card.Meta title={document.title} />
                                    {hasTrial && !hasExpired && (
                                        <Tag color="blue" className="mt-4">
                                            {t(
                                                "document.trial.expires",
                                                "Trial expires"
                                            )}{" "}
                                            {trialExpirationDate ? new Date(trialExpirationDate).toLocaleDateString("sl-SI") : "N/A"}
                                        </Tag>
                                    )}
                                </Card>
                                {!hasExpired && (
                                    <Link
                                        to={`/content-documents/show/${document.id}`}
                                        className="absolute top-0 left-0 right-0 bottom-0"
                                    />
                                )}
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const ContentDocumentTableList: React.FC = () => {
    const { tableProps, tableQueryResult } = useTable<ContentDocumentType>({
        syncWithLocation: true,
        resource: "content-documents",
    });
    const { data: identity } = useGetIdentity<IIdentity>();
    const { t } = useTranslation();

    if (tableQueryResult.isLoading || !identity) {
        return <div>Loading...</div>;
    }

    const columns = [
        { title: t("fields.id", "ID"), dataIndex: "id", key: "id" },
        { title: t("fields.title", "Title"), dataIndex: "title", key: "title" },
        {
            title: t("fields.created_at", "Created At"),
            dataIndex: "created_at",
            key: "created_at",
            render: (value: string) => new Date(value).toLocaleString(),
        },
        {
            title: t("fields.updated_at", "Updated At"),
            dataIndex: "updated_at",
            key: "updated_at",
            render: (value: string) => new Date(value).toLocaleString(),
        },
        {
            title: t("tableHeaders.actions"),
            key: "actions",
            render: (_: React.ReactNode, record: ContentDocumentType) => (
                <Space align="end">
                    <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                </Space>
            ),
        },
    ];

    return <Table {...tableProps} columns={columns} rowKey="id" />;
};

export const ContentDocumentList: React.FC = () => {
    return (
        <ContentList
            cardComponent={ContentDocumentCardList}
            tableComponent={ContentDocumentTableList}
            cookieKey="content-documents-view"
        />
    );
};
