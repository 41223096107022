import { Create, useForm } from "@refinedev/antd";
import { UserGroupForm } from "./form";

export const UserGroupCreate = () => {
    const { formProps, saveButtonProps } = useForm({});

    return (
        <Create saveButtonProps={saveButtonProps}>
            <UserGroupForm formProps={formProps} data={undefined} />
        </Create>
    );
};
