import { Tag } from "antd";

export const renderEvaluationStatusTag = (value: string, t: (key: string, defaultValue: string) => string) => {
    switch (value) {
        case "draft":
            return (
                <Tag color="default">
                    {t("evaluations.status.draft", "Draft")}
                </Tag>
            );
        case "in_progress":
            return (
                <Tag color="processing">
                    {t("evaluations.status.inProgress", "In Progress")}
                </Tag>
            );
        case "completed":
            return (
                <Tag color="success">
                    {t("evaluations.status.completed", "Completed")}
                </Tag>
            );
        case "error":
        case "failed":
            return (
                <Tag color="error">
                    {t("evaluations.status.failed", "Failed")}
                </Tag>
            );
    }
};
