import React, { useState } from "react";
import { useForm } from "@refinedev/antd";
import { Form, Input, Switch, Collapse } from "antd";
import {
    useCustomMutation,
    useCustom,
    useApiUrl,
    useTranslate,
} from "@refinedev/core";
import { Card, Button } from "antd";
import { MarkdownEditor } from "components/markdown-editor";

const { Panel } = Collapse;

const InfoEditPage: React.FC = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();

    const { data: settings, isLoading } = useCustom({
        url: `${apiUrl}/settings/`,
        method: "get",
    });

    const { mutate } = useCustomMutation();

    const { formProps, saveButtonProps } = useForm({});

    const onFinish = async (values: any) => {
        mutate({
            url: `${apiUrl}/settings/`,
            method: "put",
            values,
            successNotification: {
                type: "success",
                message: t(
                    "info.edit.success.title",
                    "Information updated successfully"
                ),
            },
        });
    };

    const [activeKeys, setActiveKeys] = useState<string[]>(["1"]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-4 max-w-3xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">
                {t("info.edit.title", "Edit Information")}
            </h1>
            <Card>
                <Form
                    {...formProps}
                    layout="vertical"
                    initialValues={settings?.data}
                    form={formProps?.form}
                    onFinish={onFinish}
                >
                    <Collapse
                        className="mb-4"
                        activeKey={activeKeys}
                        onChange={(keys) =>
                            setActiveKeys(Array.isArray(keys) ? keys : [keys])
                        }
                    >
                        <Panel
                            header={t("info.edit.appInfo", "App Information")}
                            key="1"
                            data-panel-key="1"
                        >
                            <Form.Item
                                name="app_description"
                                required
                                label={t(
                                    "info.edit.appDescription",
                                    "App Description"
                                )}
                            >
                                <MarkdownEditor />
                            </Form.Item>
                        </Panel>

                        <Panel
                            header={t(
                                "info.edit.consultingSettings",
                                "Consulting Settings"
                            )}
                            key="2"
                            data-panel-key="2"
                        >
                            <Form.Item
                                name="consulting_information"
                                required
                                label={t(
                                    "info.edit.consultingInformation",
                                    "Consulting Information"
                                )}
                            >
                                <MarkdownEditor />
                            </Form.Item>
                            <Form.Item
                                name="consulting_email"
                                required
                                rules={[
                                    {
                                        type: "email",
                                        message:
                                            "The input is not valid E-mail!",
                                    },
                                    {
                                        required: true,
                                        message: "Please input your E-mail!",
                                    },
                                ]}
                                label={t(
                                    "info.edit.consultingEmail",
                                    "Consulting Email Address"
                                )}
                                extra={t(
                                    "info.edit.consultingEmailExtra",
                                    "This is the email address to which emails will be sent when the user requests human verification of their message."
                                )}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="enable_consulting_email"
                                required
                                label={t(
                                    "info.edit.enableConsultingEmail",
                                    "Enable Consulting Email Feature"
                                )}
                                extra={t(
                                    "info.edit.enableConsultingEmailextra",
                                    "Check this if you want to enable the consulting email feature"
                                )}
                            >
                                <Switch />
                            </Form.Item>
                        </Panel>

                        <Panel
                            header={t("info.edit.disclaimers", "Disclaimers")}
                            key="3"
                            data-panel-key="3"
                        >
                            <Form.Item
                                name="disclaimer_long"
                                required
                                label={t(
                                    "info.edit.disclaimerLong",
                                    "Long Disclaimer"
                                )}
                                extra={t(
                                    "info.edit.disclaimerLongextra",
                                    "This will be displayed on the information page"
                                )}
                            >
                                <MarkdownEditor />
                            </Form.Item>
                            <Form.Item
                                name="disclaimer_short"
                                required
                                label={t(
                                    "info.edit.disclaimerShort",
                                    "Short Disclaimer"
                                )}
                                extra={t(
                                    "info.edit.disclaimerShortextra",
                                    "This will be displayed in the footer of the chatbot"
                                )}
                            >
                                <Input.TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                name="show_assistant_disclaimer"
                                required
                                label={t(
                                    "info.edit.showAssistantDisclaimer",
                                    "Show Assistant Disclaimer"
                                )}
                                extra={t(
                                    "info.edit.showAssistantDisclaimerextra",
                                    "Check this if you want to show the short disclaimer below the assistant's input field"
                                )}
                            >
                                <Switch />
                            </Form.Item>
                        </Panel>
                        <Panel
                            header={t(
                                "info.edit.firstTimeInfo",
                                "First Time Information"
                            )}
                            key="4"
                            data-panel-key="4"
                        >
                            <Form.Item
                                name="first_time_information_popup_text"
                                required
                                label={t(
                                    "info.edit.firstTimeInformationPopupText",
                                    "First Time Information Popup Text"
                                )}
                                extra={t(
                                    "info.edit.firstTimeInformationPopupTextextra",
                                    "This will be displayed on the first time information popup"
                                )}
                            >
                                <MarkdownEditor />
                            </Form.Item>
                        </Panel>
                        <Panel
                            header={t(
                                "info.edit.trialsExpirations",
                                "Trials & Expirations"
                            )}
                            key="5"
                            data-panel-key="5"
                        >
                            <Form.Item
                                name="trial_expired_text"
                                required
                                label={t(
                                    "info.edit.trialExpiredText",
                                    "Trial Expired Text"
                                )}
                                extra={t(
                                    "info.edit.trialExpiredTextExtra",
                                    "This message will be shown to users upon login when their trial period expires."
                                )}
                            >
                                <MarkdownEditor />
                            </Form.Item>
                            <Form.Item
                                name="subscription_expired_text"
                                required
                                label={t(
                                    "info.edit.subscriptionExpiredText",
                                    "Subscription Expired Text"
                                )}
                                extra={t(
                                    "info.edit.subscriptionExpiredTextExtra",
                                    "This message will be shown to users upon login when their paid subscription expires."
                                )}
                            >
                                <MarkdownEditor />
                            </Form.Item>
                            <p className="text-sm text-gray-500 mt-2 mb-4">
                                {t(
                                    "info.edit.expirationTextExplanation",
                                    "These messages will be displayed to users when they attempt to log in after their trial or paid subscription has expired. Use them to inform users about the expiration and guide them on how to renew or upgrade their account."
                                )}
                            </p>
                        </Panel>
                        <Panel
                            header={t("info.edit.footerSettings", "Footer Settings")}
                            key="6"
                            data-panel-key="6"
                        >
                            <Form.Item
                                name="footer_enabled"
                                valuePropName="checked"
                                label={t(
                                    "info.edit.footerEnabled",
                                    "Enable Footer"
                                )}
                                extra={t(
                                    "info.edit.footerEnabledExtra",
                                    "Check this if you want to enable the footer"
                                )}
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                name="footer_text"
                                required
                                label={t(
                                    "info.edit.footerText",
                                    "Footer Text"
                                )}
                                extra={t(
                                    "info.edit.footerTextExtra",
                                    "This text will be displayed in the footer"
                                )}
                            >
                                <Input.TextArea rows={3} />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <Form.Item className="flex justify-end">
                        <Button type="primary" {...saveButtonProps}>
                            {t("info.edit.save", "Save Changes")}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default InfoEditPage;
