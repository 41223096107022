import React from "react";

import { Col, Row } from "antd";
import { ChatMessagesTable } from "./components/table";
import { InfoCard } from "./components/info-card";
import { useTranslation } from "react-i18next";
import { DateRange } from "./types";
import { useCustom, useApiUrl } from "@refinedev/core";

const InfoCardRow = () => {
    const { t } = useTranslation();

    interface StatsResponse {
        assistant_id: string;
        message_count: number;
        chat_count: number;
        hint_count: number;
        explanation_count: number;
        message_count_change: number;
        chat_count_change: number;
        hint_count_change: number;
        explanation_count_change: number;
    }

    const apiUrl = useApiUrl();
    const { data, isLoading } = useCustom<StatsResponse[]>({
        url: `${apiUrl}/stats-summary`,
        method: "get",
    });

    if (isLoading || !data) {
        return null;
    }

    const arrayOfResulsts = data?.data || [];
    const defaultData = {
        assistant_id: "all",
        message_count: 0,
        chat_count: 0,
        hint_count: 0,
        explanation_count: 0,
        message_count_change: 0,
        chat_count_change: 0,
        hint_count_change: 0,
        explanation_count_change: 0,
    };
    const dataAll =
        arrayOfResulsts.find(
            (result: StatsResponse) => result.assistant_id === "all"
        ) || defaultData;
    const dateRange = DateRange.LastWeek;

    return (
        <Row justify="space-between" gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <InfoCard
                    color="darkPurple"
                    dateRange={dateRange}
                    title={t("stats.messages", "Messages")}
                    value={dataAll.message_count}
                    change={dataAll.message_count_change}
                />
            </Col>
            <Col xs={24} md={12}>
                <InfoCard
                    color="darkGreen"
                    dateRange={dateRange}
                    title={t("stats.conversations", "Conversations")}
                    value={dataAll.chat_count}
                    change={dataAll.chat_count_change}
                />
            </Col>
        </Row>
    );
};

export const DashboardPage: React.FC = () => {
    return (
        <div className="page-container">
            <InfoCardRow />
            <Row justify="space-between">
                <Col xs={24} sm={24} md={24} xl={24}>
                    <ChatMessagesTable />
                </Col>
            </Row>
        </div>
    );
};
