import React, { useState } from "react";
import { Table, Input, Button } from "antd";
import { useParams } from "react-router-dom";
import { useCreate, useList, useUpdate } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import { DeleteButton, EditButton, SaveButton } from "@refinedev/antd";
import { EvaluationQuestion } from "pages/evaluation-question-sets/types";

export const EvaluationQuestionTable: React.FC = () => {
    const { t } = useTranslation();
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editedValues, setEditedValues] = useState<Partial<EvaluationQuestion>>({});

    const { id: questionSetId } = useParams();

    const { data: questions } = useList({
        resource: "evaluation-questions",
        filters: [
            {
                field: "question_set",
                operator: "eq",
                value: questionSetId,
            },
        ],
    });

    const sortedQuestions = questions?.data.sort((a: EvaluationQuestion, b: EvaluationQuestion) => a.id - b.id);

    const isEditing = (record: EvaluationQuestion) => record.id === editingKey;

    const edit = (record: EvaluationQuestion) => {
        setEditingKey(record.id);
        setEditedValues(record);
    };

    const { mutate: saveQuestion } = useUpdate();

    const save = (key: number) => {
        saveQuestion({
            resource: "evaluation-questions",
            id: key,
            values: editedValues,
        });
        setEditingKey(null);
        setEditedValues({});
    };

    const { mutate: createQuestion } = useCreate();

    const create = () => {
        createQuestion({
            resource: "evaluation-questions",
            values: {
                question_set: questionSetId,
                question: "Change me",
                ground_truth: "Change me",
                additional_instructions: "Change me",
            },
        });
    };


    const handleInputChange = (key: keyof EvaluationQuestion, value: string) => {
        setEditedValues((prev) => ({ ...prev, [key]: value }));
    };

    const columns = [
        {
            title: t("evaluationQuestions.columns.question"),
            dataIndex: "question",
            key: "question",
            render: (text: string, record: EvaluationQuestion) => {
                const editable = isEditing(record);
                return editable ? (
                    <Input
                        value={editedValues.question ?? ""}
                        onChange={(e) =>
                            handleInputChange("question", e.target.value)
                        }
                        placeholder={t(
                            "evaluationQuestions.placeholders.enterQuestion"
                        )}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: t("evaluationQuestions.columns.groundTruth"),
            dataIndex: "ground_truth",
            key: "ground_truth",
            render: (text: string, record: EvaluationQuestion) => {
                const editable = isEditing(record);
                return editable ? (
                    <Input
                        value={editedValues.ground_truth ?? ""}
                        onChange={(e) =>
                            handleInputChange("ground_truth", e.target.value)
                        }
                        placeholder={t(
                            "evaluationQuestions.placeholders.enterGroundTruth"
                        )}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: t("evaluationQuestions.columns.additionalInstructions"),
            dataIndex: "additional_instructions",
            key: "additional_instructions",
            render: (text: string, record: EvaluationQuestion) => {
                const editable = isEditing(record);
                return editable ? (
                    <Input
                        value={editedValues.additional_instructions ?? ""}
                        onChange={(e) =>
                            handleInputChange(
                                "additional_instructions",
                                e.target.value
                            )
                        }
                        placeholder={t(
                            "evaluationQuestions.placeholders.enterAdditionalInstructions"
                        )}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: t("evaluationQuestions.columns.actions"),
            key: "actions",
            render: (record: EvaluationQuestion) => {
                const editable = isEditing(record);
                return editable ? (
                    <SaveButton
                        size="small"
                        hideText
                        onClick={() => save(record.id)}
                        title={t("evaluationQuestions.actions.save")}
                    />
                ) : (
                    <>
                        <EditButton
                            size="small"
                            hideText
                            onClick={() => edit(record)}
                            className="mr-2"
                            title={t("evaluationQuestions.actions.edit")}
                        />
                        <DeleteButton
                            size="small"
                            resource="evaluation-questions"
                            recordItemId={record.id}
                            hideText
                            title={t("evaluationQuestions.actions.delete")}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <Table columns={columns} dataSource={sortedQuestions} rowKey="id" />
            <Button onClick={create}>
                {t("evaluationQuestions.actions.addQuestion")}
            </Button>
        </div>
    );
};
