import React, { useState } from "react";
import { Modal, Form, Input, Checkbox } from "antd";
import { useForm } from "@refinedev/antd";
import ReactMarkdown from "react-markdown";
import { useApiUrl, useCreate, useCustom } from "@refinedev/core";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

interface ConsultingIntegrationModalProps {
    visible: boolean;
    onClose: () => void;
    chatMessageHistoryId: number;
}

export const ConsultingIntegrationModal: React.FC<
    ConsultingIntegrationModalProps
> = ({ visible, onClose, chatMessageHistoryId }) => {
    const { form, formProps } = useForm();
    const [includeHistory, setIncludeHistory] = useState(false);
    const { t } = useTranslation();

    const { mutate } = useCreate({});

    const handleSubmit = async (values: any) => {
        mutate({
            resource: "consulting-requests",
            values: {
                problem_description: values.problemDescription,
                include_history: includeHistory,
                conversation_history: chatMessageHistoryId,
            },
            successNotification: () => {
                onClose();
                return {
                    type: "success",
                    message: t(
                        "consultingIntegrationModal.success",
                        "Request sent successfully"
                    ),
                };
            },
        });
    };

    const apiUrl = useApiUrl();

    const { data: settings } = useCustom({
        url: `${apiUrl}/settings/`,
        method: "get",
    });

    return (
        <Modal
            title={t(
                "consultingIntegrationModal.title",
                "Confirm Answer with Human"
            )}
            onCancel={onClose}
            open={visible}
            onOk={form.submit}
            okText={t("consultingIntegrationModal.sendEmail", "Send Email")}
            cancelText={t("consultingIntegrationModal.cancel", "Cancel")}
        >
            <Form {...formProps} onFinish={handleSubmit} layout="vertical">
                <Form.Item>
                    <ReactMarkdown className="p-4 bg-gray-100 rounded">
                        {settings?.data?.consulting_information}
                    </ReactMarkdown>
                </Form.Item>
                <Form.Item
                    name="problemDescription"
                    label={t(
                        "consultingIntegrationModal.problemDescriptionLabel",
                        "Problem Description"
                    )}
                    rules={[
                        {
                            required: true,
                            message: t(
                                "consultingIntegrationModal.problemDescriptionRequired",
                                "Please enter the problem description"
                            ),
                        },
                    ]}
                >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        checked={includeHistory}
                        onChange={(e) => setIncludeHistory(e.target.checked)}
                    >
                        {t(
                            "consultingIntegrationModal.includeHistory",
                            "Include current conversation history"
                        )}
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};
