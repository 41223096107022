import { useGetIdentity, useOne } from "@refinedev/core";
import { IIdentity } from "../authProvider";
import { mapAvailableFeatures } from "./availableFeatures";

export function useCurrentUserGroup() {
    const { data: identity } = useGetIdentity<IIdentity>();
    const { data: userGroupData } = useOne({
        resource: "user-groups",
        id: identity?.user_group,
        queryOptions: {
            enabled: !!identity?.user_group,
        },
    });

    const userGroup = userGroupData?.data;

    const features = userGroup?.available_features
        ? mapAvailableFeatures(userGroup.available_features)
        : [];

    const userGroupDisplayName = userGroup?.group_display_name;
    const logo = userGroup?.logo_image || null;
    const usageLimit = userGroup?.monthly_usage_limit || 0;
    const productName = userGroup?.product_name || null;
    const isExpired = userGroup?.subscription_expiration_date ? new Date() > new Date(userGroup.subscription_expiration_date) : false;

    const isOverUsageLimit = userGroup?.monthly_usage_limit && userGroup?.monthly_usage_limit > 0 && userGroup?.monthly_usage_limit < userGroup?.usage;

    return { features, userGroupDisplayName, logo, usageLimit, productName, isExpired, isOverUsageLimit };
}
