import {
    DeleteButton,
    EditButton,
    List,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { BaseRecord } from "@refinedev/core";
import { Space, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";

export const UserList = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const { t } = useTranslation();

    return (
        <List title={t("resources.users", "Users")} canCreate={false}>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title={t("tableHeaders.id", "ID")} />
                <Table.Column dataIndex="name" title={t("tableHeaders.name", "Name")} />
                <Table.Column dataIndex="email" title={t("tableHeaders.email", "Email")} />
                <Table.Column dataIndex="usage" title={t("tableHeaders.usage", "Usage")} />
                <Table.Column dataIndex="user_group_name" title={t("tableHeaders.group", "Group")} />
                <Table.Column
                    dataIndex="is_superuser"
                    title={t("tableHeaders.role", "Role")}
                    render={(is_superuser: boolean) =>
                        is_superuser ? (
                            <Tag color="red">{t("tableHeaders.admin", "Admin")}</Tag>
                        ) : (
                            <Tag color="green">{t("tableHeaders.user", "User")}</Tag>
                        )
                    }
                />
                <Table.Column
                    title={t("tableHeaders.actions", "Actions")}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => {
                        return (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};
