import React, { useState } from "react";
import { Table, InputNumber, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import { useList, useUpdate } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import { EditButton, SaveButton } from "@refinedev/antd";
import ReactMarkdown from "react-markdown";

interface EvaluationAnswer {
    id: number;
    frozen_question: string;
    frozen_ground_truth: string;
    frozen_additional_instructions: string;
    generated_answer: string;
    automatic_grade: number | null;
    human_grade: number | null;
}

export const EvaluationAnswerTable: React.FC = () => {
    const { t } = useTranslation();
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editedValues, setEditedValues] = useState<Partial<EvaluationAnswer>>(
        {}
    );

    const { id: evaluationId } = useParams();

    const { data: answers } = useList({
        resource: "evaluation-answers",
        filters: [
            {
                field: "evaluation",
                operator: "eq",
                value: evaluationId,
            },
        ],
    });

    const sortedAnswers = answers?.data.sort(
        (a: EvaluationAnswer, b: EvaluationAnswer) => a.id - b.id
    );

    const isEditing = (record: EvaluationAnswer) => record.id === editingKey;

    const edit = (record: EvaluationAnswer) => {
        setEditingKey(record.id);
        setEditedValues(record);
    };

    const { mutate: saveAnswer } = useUpdate();

    const save = (key: number) => {
        saveAnswer({
            resource: "evaluation-answers",
            id: key,
            values: editedValues,
        });
        setEditingKey(null);
        setEditedValues({});
    };

    const handleInputChange = (
        key: keyof EvaluationAnswer,
        value: string | number | null
    ) => {
        setEditedValues((prev) => ({ ...prev, [key]: value }));
    };

    const renderLongText = (text: string | null | undefined) => {
        if (!text) return "";
        const firstLine = text.length > 20 ? text.substring(0, 20) + "..." : text;
        return (
            <div>
                <Tooltip
                    title={<ReactMarkdown className="text-black">{text}</ReactMarkdown>}
                    overlayStyle={{
                        maxWidth: "80%",
                    }}
                    color="white"
                >
                    <span className="cursor-pointer">
                        {firstLine}
                    </span>
                </Tooltip>
            </div>
        );
    };

    const columns = [
        {
            title: t("evaluationAnswers.columns.frozenQuestion"),
            dataIndex: "frozen_question",
            key: "frozen_question",
            render: renderLongText,
        },
        {
            title: t("evaluationAnswers.columns.frozenGroundTruth"),
            dataIndex: "frozen_ground_truth",
            key: "frozen_ground_truth",
            render: renderLongText,
        },
        {
            title: t("evaluationAnswers.columns.frozenAdditionalInstructions"),
            dataIndex: "frozen_additional_instructions",
            key: "frozen_additional_instructions",
            render: renderLongText,
        },
        {
            title: t("evaluationAnswers.columns.generatedAnswer"),
            dataIndex: "generated_answer",
            key: "generated_answer",
            render: renderLongText,
        },
        {
            title: t("evaluationAnswers.columns.automaticGrade"),
            dataIndex: "automatic_grade",
            key: "automatic_grade",
            render: (text: number | null) => {
                return `${text}%`;
            },
        },
        {
            title: t("evaluationAnswers.columns.automaticGradeExplanation"),
            dataIndex: "automatic_grade_explanation",
            key: "automatic_grade_explanation",
            render: renderLongText,
        },
        {
            title: t("evaluationAnswers.columns.humanGrade"),
            dataIndex: "human_grade",
            key: "human_grade",
            render: (text: number | null, record: EvaluationAnswer) => {
                const editable = isEditing(record);
                return editable ? (
                    <InputNumber
                        value={editedValues.human_grade ?? null}
                        onChange={(value) =>
                            handleInputChange("human_grade", value)
                        }
                        placeholder={t(
                            "evaluationAnswers.placeholders.enterHumanGrade"
                        )}
                    />
                ) : (
                    `${text ? `${text}%` : ""}`
                );
            },
        },
        {
            title: t("evaluationAnswers.columns.actions"),
            key: "actions",
            render: (record: EvaluationAnswer) => {
                const editable = isEditing(record);
                return editable ? (
                    <SaveButton
                        size="small"
                        hideText
                        onClick={() => save(record.id)}
                        title={t("evaluationAnswers.actions.save")}
                    />
                ) : (
                    <EditButton
                        size="small"
                        hideText
                        onClick={() => edit(record)}
                        title={t("evaluationAnswers.actions.edit")}
                    />
                );
            },
        },
    ];

    return (
        <div>
            <Table columns={columns} dataSource={sortedAnswers} rowKey="id" />
        </div>
    );
};
