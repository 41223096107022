import { Edit, useForm } from "@refinedev/antd";
import { ContentDocumentType } from "./types";
import { ContentDocumentForm } from "./form";

export const ContentDocumentEdit = () => {
    const {formProps, queryResult, saveButtonProps} = useForm();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <ContentDocumentForm data={queryResult?.data?.data as ContentDocumentType} formProps={formProps}/>
        </Edit>
    );
};
