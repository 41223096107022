import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { EvaluationQuestionSetForm } from "./form";

export const EvaluationQuestionSetCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <EvaluationQuestionSetForm
                formProps={formProps}
            />
        </Create>
    );
};

export default EvaluationQuestionSetCreate;
