import React, { useState } from "react";
import {
    Form,
    Select,
    Input,
    Collapse,
    FormProps,
    message,
    Button,
} from "antd";
import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { AssistantSelect } from "components/assistant/assistant-select";

const { Panel } = Collapse;
const { TextArea } = Input;

interface EvaluationFormProps {
    formProps: FormProps;
}

const GRADING_PROMPT = `You are an expert, impartial, grading an AI system's answers. Grade the AI's answer based on the ground truth answer.

### QUESTION
{question}

### STUDENT ANSWER
{student_answer}

### GROUND TRUTH
{ground_truth}`;

const SUMMARY_PROMPT = `You are an expert, impartial, grading an AI system's answers.

Write a summary, and identify any common patterns, and suggestions for improvement in the following feedbacks.

### FEEDBACKS
{feedbacks}`;

export const EvaluationForm: React.FC<EvaluationFormProps> = ({
    formProps,
}) => {
    const t = useTranslate();
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);

    const { selectProps: questionSetSelectProps } = useSelect({
        resource: "evaluation-question-sets",
        optionLabel: "name",
        optionValue: "id",
    });

    const { selectProps: assistantSelectProps } = useSelect({
        resource: "assistants",
        optionLabel: "name",
        optionValue: "id",
    });

    const { selectProps: documentSelectProps } = useSelect({
        resource: "content-documents",
        optionLabel: "title",
        optionValue: "id",
    });

    const checkRequiredKeywords = (values: {
        grading_prompt?: string;
        summary_prompt?: string;
    }) => {
        const gradingPrompt = values.grading_prompt || GRADING_PROMPT;
        const summaryPrompt = values.summary_prompt || SUMMARY_PROMPT;

        const gradingKeywords = [
            "{question}",
            "{student_answer}",
            "{ground_truth}",
        ];
        const summaryKeywords = ["{feedbacks}"];

        const missingGradingKeywords = gradingKeywords.filter(
            (keyword) => !gradingPrompt.includes(keyword)
        );
        const missingSummaryKeywords = summaryKeywords.filter(
            (keyword) => !summaryPrompt.includes(keyword)
        );

        let hasErrors = false;

        if (missingGradingKeywords.length > 0) {
            message.error(
                `Missing required keywords in grading prompt: ${missingGradingKeywords.join(
                    ", "
                )}`
            );
            formProps.form?.setFields([
                {
                    name: "grading_prompt",
                    errors: [
                        `Missing required keywords: ${missingGradingKeywords.join(
                            ", "
                        )}`,
                    ],
                },
            ]);
            setIsAdvancedOpen(true);
            hasErrors = true;
        }

        if (missingSummaryKeywords.length > 0) {
            message.error(
                `Missing required keywords in summary prompt: ${missingSummaryKeywords.join(
                    ", "
                )}`
            );
            formProps.form?.setFields([
                {
                    name: "summary_prompt",
                    errors: [
                        `Missing required keywords: ${missingSummaryKeywords.join(
                            ", "
                        )}`,
                    ],
                },
            ]);
            setIsAdvancedOpen(true);
            hasErrors = true;
        }

        return !hasErrors;
    };

    const handleSubmit = async (values: {
        grading_prompt?: string;
        summary_prompt?: string;
    }) => {
        if (checkRequiredKeywords(values)) {
            await formProps.onFinish?.(values);
        }
    };

    const resetPrompt = (field: string) => {
        const defaultValue =
            field === "grading_prompt" ? GRADING_PROMPT : SUMMARY_PROMPT;
        formProps.form?.setFieldsValue({ [field]: defaultValue });
    };

    return (
        <Form {...formProps} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
                label={t("evaluations.fields.questionSet")}
                name="question_set"
                rules={[{ required: true }]}
            >
                <Select {...questionSetSelectProps} />
            </Form.Item>
            <Form.Item
                label={t("evaluations.fields.assistant")}
                name="assistant"
                rules={[{ required: true }]}
            >
                <Select {...assistantSelectProps} />
            </Form.Item>
            <Form.Item
                label={t("evaluations.fields.document")}
                name="document"
                rules={[{ required: true }]}
            >
                <Select {...documentSelectProps} />
            </Form.Item>

            <Collapse
                activeKey={isAdvancedOpen ? ["1"] : undefined}
                onChange={(keys) => setIsAdvancedOpen(keys.includes("1"))}
            >
                <Panel
                    header={t(
                        "evaluations.fields.advancedSettings",
                        "Advanced Settings"
                    )}
                    key="1"
                >
                    <Form.Item
                        label={t("evaluations.fields.languageModel")}
                        name="language_model"
                        required
                    >
                        <AssistantSelect />
                    </Form.Item>
                    <Form.Item
                        label={t("evaluations.fields.gradingPrompt")}
                        name="grading_prompt"
                        initialValue={GRADING_PROMPT}
                    >
                        <TextArea rows={18} />
                    </Form.Item>
                    <Button
                        onClick={() => resetPrompt("grading_prompt")}
                        style={{ marginBottom: 16 }}
                    >
                        {t(
                            "evaluations.fields.resetGradingPrompt",
                            "Reset Grading Prompt"
                        )}
                    </Button>
                    <Form.Item
                        label={t("evaluations.fields.summaryPrompt")}
                        name="summary_prompt"
                        initialValue={SUMMARY_PROMPT}
                    >
                        <TextArea rows={12} />
                    </Form.Item>
                    <Button onClick={() => resetPrompt("summary_prompt")}>
                        {t(
                            "evaluations.fields.resetSummaryPrompt",
                            "Reset Summary Prompt"
                        )}
                    </Button>
                </Panel>
            </Collapse>
        </Form>
    );
};

export default EvaluationForm;
