import React from "react";
import { IResourceComponentsProps, useShow, useTranslate } from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Typography, Tag } from "antd";
import { EvaluationQuestionSet, EvaluationQuestion } from "./types";

const { Title, Text } = Typography;

export const EvaluationQuestionSetShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow<EvaluationQuestionSet>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{translate("questionSets.fields.id")}</Title>
      <Text>{record?.id}</Text>

      <Title level={5}>{translate("questionSets.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{translate("questionSets.fields.description")}</Title>
      <Text>{record?.description}</Text>

      <Title level={5}>{translate("questionSets.fields.questions")}</Title>
      {record?.questions.map((question: EvaluationQuestion) => (
        <Tag key={question.id}>{question.text}</Tag>
      ))}
    </Show>
  );
};

export default EvaluationQuestionSetShow;
