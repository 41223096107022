import { useCustom, useApiUrl } from "@refinedev/core";
import React from "react";
import ReactMarkdown from "react-markdown";


const Footer: React.FC = () => {
    const apiUrl = useApiUrl();
    const { data: settings } = useCustom({
        url: `${apiUrl}/settings/`,
        method: "get",
    });

    if (!settings?.data?.footer_enabled) {
        return null;
    }

    return (
        <footer className="bg-gray-800 text-white text-center p-4">
            <ReactMarkdown components={{
                a: ({...props}) => <a {...props} className="text-white hover:text-gray-300" rel="noopener noreferrer" target="_blank" />
            }}>{settings?.data?.footer_text}</ReactMarkdown>
        </footer>
    );
};

export { Footer };
