import { Form, FormProps, Input, Select } from "antd";
import { UserData } from "./types";
import { useSelect } from "@refinedev/antd";
import { useTranslation } from 'react-i18next'; // Assuming useTranslation hook is used for i18n

export const UserForm = ({
    formProps,
    data,
}: {
    formProps: FormProps;
    data: UserData | undefined;
}) => {
    const { t } = useTranslation();
    const { selectProps: userGroupSelectProps } = useSelect({
        resource: "user-groups",
        optionLabel(item) {
            return item?.name;
        },
    });

    return (
        <Form {...formProps} layout="vertical" initialValues={data}>
            <Form.Item
                label={t("users.fields.name", "Name")}
                name={["name"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("users.fields.email", "Email")}
                name={["email"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("users.fields.group", "Group")}
                name={["user_group"]}
                rules={[{ required: true }]}
            >
                <Select rootClassName="w-full" {...userGroupSelectProps} />
            </Form.Item>
        </Form>
    );
};
